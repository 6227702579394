.mx-progress-main{
  padding: 0px 5px;
}
.mx-progress-main.center{
    text-align: center;
}
.mx-progress-main.left{
    text-align: left;
}
.mx-progress-main.right{
    text-align: right;
}
.mx-progress-wrap{
  width: 100%;
  height: 3px;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 2px #0005;
  overflow: hidden;
}
.mx-progress-bar{
  height: 100%;
  background-color: #959595;
}
.mx-progress-number{
  font-size: 12px;
  font-weight: 700;
}