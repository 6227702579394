/* Dashboard styles */


.mx-details-head{
    cursor: pointer;
    margin-bottom: 15px;
    background-color: #ffffff;
    padding: 0.5em 1em;
    border-radius: 5px;
    font-weight: 600;
    color: #151515;
    font-size: 16px;
}
.mx-details-body{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 30px;
}
.mx-details-scrollable{
    max-height: 35vh;
    overflow-y: auto;
}
.mx-details-notes{
    width: 100%;
    padding: 5px 10px;
    margin: 0px 5px;
    border-radius: 5px;
    background-color: var(--bg-color-pr);
    color: var(--white);
}
.mx-details-notes i{
    margin-right: 12px;
}
.mx-details-info{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-right: 5px;
    font-size: 20px;
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.mx-details-info span{
    font-size: 14px;
}
.mx-details-info >*{
    padding: 5px 10px;
    white-space: nowrap;
    background-color: #252525;
    color: #f0f0f0;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    position: absolute;
    bottom: calc(100% + 5px);
    right: 0%;
    display: none;
}
.mx-details-info:hover >*{
    display: block;
}
.mx-details-inner{
    width: calc(25% - 15px);
    padding: 0px 15px;
    background-color: #ffffff;
}
.mx-details-inner.align-self-start{
    align-self: flex-start;
}
.mx-details-title{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    margin-top: 0.8em;
    font-weight: 600;
}
.mx-step .mx-step-status{
    position: relative;
    background-color: #fff;
}
.mx-step .mx-step-status-line{
    height: 100%;
    width: 2px;
    background-color: currentColor;
    position: absolute;
    top: 5px;
    left: 20px;
}
.mx-step{
    margin-top: 10px;
    display: flex;
    gap: 10px;
    color: rgb(123, 123, 123);
    position: relative;
}
.mx-step.in-progress{
    color: #ffbf13;
}
.mx-step.completed{
    color: #80c44d;
}
.mx-step.in-progress .mx-step-status i{
    animation: spin 6000ms linear infinite;
}
.mx-step-status{
    font-size: 20px;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid currentColor;
}
.mx-step-desc{
    color: #252525;
}
.mx-step-detail{
    color: #858585;
    font-size: 95%;
}




/* @media(max-width:1600px){
    .mx-details-body{
        flex-wrap: wrap;
    }
    .mx-details-inner{
        width: calc(33% - 15px);
    }
} */
@media(max-width:1600px){
    .mx-details-body{
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .mx-details-inner{
        width: calc(50% - 15px);
    }
}
@media(max-width:767px){
    .mx-details-inner{
        width: 100%;
    }
}