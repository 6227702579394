.form-row{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    margin: 10px 0px;
}
.form-row >*{
    flex-grow: 1;
}
.form-wrap{
    padding: 15px 10px;
    border: 1px solid #b5b5b5;
    background-color: #ffffff;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    margin: 20px 30px;
    border-radius: 10px;
    width: calc(100% - 20px);
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
.form-wrap .form-title{
    display: block;
    text-align: center;
    font-size: 30px;
    margin-bottom: 1em;
    color: #000066;
}
.form-input-wrap.justify-center{
    display: flex;
    justify-content: center;
}
.form-input-wrap{
    margin: 5px 0px;
}
.form-input-wrap input:not([type="submit"]),
.form-input-wrap textarea,
.form-input-wrap select{
    width: 100%;
    border-radius: 5px;
    border: 1px solid #b5b5b5;
    padding: 0.4em 1em;
    color: #000066;
}
.form-input-wrap input:not([type="submit"]):focus,
.form-input-wrap textarea:focus,
.form-input-wrap select:focus{
    outline: none;
    border-color: #80c44d;
}