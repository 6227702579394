.mx-report-dropdown-head{
    margin-top: 10px;
    border: 1px solid #b5b5b5;
    border-radius: 5px;
    background-color: #ffffff;
    display: block;
    width: 100%;
    padding: 0.5em 1em;
    text-align: left;
}
.mx-report{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.5em 0em;
    font-size: 16px;
}
.mx-report > span{
    font-size: 14px;
    font-weight: 300;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    padding: 0px;
    overflow: hidden;
}
.mx-report > div {
    display: flex;
    align-items: center;
}
.mx-report-dropdown-child{
    margin-top: 10px;
}
.mx-report-download-all{
    padding-bottom: 5px;
    background-color: var(--white);
    position: sticky;
    top: 0px;
}
.mx-report-download-all-button{
    width: 100%;
    border: none;
    background-color: var(--bg-color-pr);
    color: var(--white);
    border-radius: 5px;
    padding: 0.3em 1em;
    cursor: pointer;
}