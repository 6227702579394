.mx-engineer{
    margin: 15px 0px;
    display: grid;
    grid-template-columns: 0fr 1fr 0fr 0fr;
    column-gap: 10px;
    align-items: center;
}
.mx-engineer-status{
    height: 10px;
    width: 10px;
    background-color: #b5b5b5;
    border-radius: 50%;
}
.mx-engineer.online .mx-engineer-status,
.mx-engineer.online .mx-engineer-call{
    background-color: #80c44d;
}

.mx-engineer-info,
.mx-engineer-call{
    border: none;
    border-radius: 1em;
    height: 30px;
    width: 30px;
    background-color: #b5b5b5;
    color: #0a003f;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mx-engineer-info{
    background-color: transparent;
    position: relative;
    font-size: 30px;
    color: var(--text-gray);
}
.mx-engineer-info-main{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 12px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    background-color: #252525;
    color: #f0f0f0;
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translate(-10px,-50%);
    box-shadow: 0px 0px 5px #0004;
    display: none;
    pointer-events: none;
}
.mx-engineer-info-main::after{
    content: "";
    position: absolute;
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #252525;
    width: 0;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
}
.mx-engineer-info-row{
    white-space: nowrap;
    margin: 0.6em 10px;
}
.mx-engineer-info:hover .mx-engineer-info-main{
    display: block;
}

.mx-engineer-call:hover{
    cursor: pointer;
}