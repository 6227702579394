.mx-chat-widget{
    max-height: 35vh;
    height: 100%;
    margin: 10px 0px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: calc(100% - 56px);
}
.mx-chat-widget-message{
    max-width: 80%;
    margin-right: auto;
}
.mx-chat-widget-message-text{
    margin-top: 5px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #b5b5b5;
    word-break: break-word;
    background-color:#f0f0f0;
    color: #000;
}
.mx-chat-widget-message-date{
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    position: sticky;
    top: 0px;
    background-color: var(--white);
}
.mx-chat-widget-message-line{
    flex-grow: 1;
    border-top: 1px solid #d5d5d5;
}
.mx-chat-widget-message-time{
    display: block;
    text-align: right;
    font-size: 10px;
    font-weight: 700;
    color: #353535;
    letter-spacing: 1px;
}
.mx-chat-widget-message.is-own{
    margin-left: auto;
    margin-right: 0px;
}
.mx-chat-widget-message.is-own .mx-chat-widget-message-text{
    background-color: #80c44d;
    border-color: transparent;
}
.mx-chat-widget-message.is-own .mx-chat-widget-message-name{
    text-align: right;
}
.mx-chat-widget-message-name{
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
}
.mx-chat-widget-send{
    position: sticky;
    bottom: 0px;
    background-color: #ffffff;
    padding: 10px;
    padding-bottom: 0px;
    margin-top: auto;
    display: flex;
}
.mx-chat-widget-send input{
    padding: 0.3em;
    font-size: 14px;
    line-height: 1em;
    background-color: #ffffff;
    width: 100%;
}
.mx-chat-widget-send button{
    padding: 0.3em 1.5em;
    background-color: #80c44d;
    cursor: pointer;
}
.mx-chat-widget-send input,
.mx-chat-widget-send button{
    border: 1px solid #b5b5b5;
}