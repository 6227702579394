.mx-team-main{
    display: flex;
}
.mx-team-tooltip{
    position: absolute;
    z-index: 99;
    color: #fff;
    background-color: #151515;
    padding: 5px 10px;
    font-size: 14px;
    bottom: calc(100% + 12px);
    left:50%;
    transform: translateX(-50%);
    white-space: nowrap;
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 10px #0005;
    display: none;
}
.mx-team-tooltip::after{
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #151515;
}
.mx-team-image:hover .mx-team-tooltip,
.mx-team-noimage:hover .mx-team-tooltip{
    display: block;
}
.mx-team-image,
.mx-team-noimage{
    height: 30px;
    width: 30px;
    position: relative;
}
.mx-team-image:nth-child(even) .mx-team-noimage{
    background-color: var(--blue);

}
.mx-team-noimage{
    font-size: 12px;
    background-color: #80c44d;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    color: #0a003f;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 5px #0005;
    border-radius: 50%;
}
.mx-team-image:not(:first-child){
    margin-left: -10px;
}
.mx-team-image img{
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 5px #0005;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}