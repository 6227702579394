@import url(https://fonts.googleapis.com/css2?family=Audiowide&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Muli', Arial, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}
.HeadImage
{
  height:auto;
  background-color:blue;
}
.feature-card
{
  height:auto;
}
#CardHeight
{
  height: auto;
}
.container p
{
  text-align: justify;
  margin-top: 20px;
}
ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1 em;
  margin: auto;
  padding-left: 150px;
  text-align: left;
  
}
.col-sm-6 h3 u
{
  text-align: left;
}
.body-text
{
  text-align: justify;
}
.container-fluaid {
  position: relative;
  text-align: center;
  color: white;
}
/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.top-left h1
{
  background-color: rgb(45, 161, 255);
  opacity:0.6;
  color: white;
  border-radius: 8px;
}
/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 18px;
  left: 16px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Paragraph p
{
  text-align: justify;
}
#video2
{
  background: cover;
}
#Palyer
{
  height: 100%;
}
#Table
{
  border:1.5px solid black;
  background-color: #dbdbdb47;
}
#callScreen{
  position: absolute;
    top: 0;
    z-index: 48;
    width:100%;
    height:100%;
    background-color: black;
}
.app-subscriber
  {
    position: absolute;
    top: 0;
    z-index: 50;
}
.app-publisher
  {
    position: absolute;
    top: 100px;
    right: 100px;
    /* z-index: 100; */
    border: 3px solid white;
    border-radius: 3px;
    z-index: 150;
}
/* .NewPageLoader
{
  height: 100%;
  padding: 18%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  background-color: white;
} */
#video{
  position:relative;
  margin:0 auto;
  left:0px;
  right:0px;
  text-align: center;  
  
    background: #fff;
  }
  .close {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100px;
    background: orange;
    text-align: center;
    padding: 10px;
    display: none;
   
  }
  
  
  .close:hover {
    cursor: pointer;
    background-color: orange;
  }
  
  #video:hover .close {
    display: block;
  }
  .ellips {
    height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p{
    margin-bottom: 0;
  }
  .accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  position: relative;
  background-color: #f4f4f4;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
}

.accordion__button:hover {
    background-color: #ddd;
}

.accordion__button:before {
  
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-bottom: 6px double currentColor;
  border-right: 6px double currentColor;
  transform: rotate(45deg) translateY(-50%);
  right: 1.5em;
  top: 50%;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(225deg) translateY(50%);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
}
.mx-form-bg{
  padding: 1px;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #fafaff;
}
.mx-form-card{
  width: 80%;
  margin: 50px auto;
  background-color: #ffffff;
  box-shadow: 0px -60px 30px -60px #0002,0px 60px 30px -60px #0002, 60px 0px 30px -60px #0002, -60px 0px 30px -60px #0002;
  border-radius: 20px;
  border: 1px solid #e5e5e5;
  padding: 40px 20px;
}
.mx-form-wrap{
  padding: 0px 5%;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
/* .callcontroldiv{
  display: none;
  position: absolute;
  top: 90%;
  z-index: 100;
  background:rgba(0,0,0,0.5);
  width:100%
} */
/* .app-subscriber:hover .callcontroldiv{
  display: flex;
} */
/* .fa.fa-video-camera:after {
  position: absolute;
  content: "";
  left: 1.33em;
  top: 0;
  width: .2em;
  height: 2.5em;
  display: block;
  background: white;
  transform: rotate(38deg);
  border-right: .1em solid black;
} */

:root{
  --bg-color:#f4f7f6;
  --bg-color-pr:#313740;
  --bg-color-pr-light:#414751;
  --bg-color-sc:#55ba18;
  --bg-color-sc-light:#73db33;
  --white:#ffffff;
  --text-color:#212121;
  --text-gray:#afafaf;
  --yellow:rgb(244 183 35);
  --green:rgb(85 186 24);
  --blue:rgb(89 203 255);
}
.clr-pr{
  color: #313740;
  color: var(--bg-color-pr);
}
.clr-sc{
  color: #55ba18;
  color: var(--bg-color-sc);
}
.hover\:clr-pr:hover{
  color: #313740;
  color: var(--bg-color-pr);
}
.hover\:clr-sc:hover{
  color: #55ba18;
  color: var(--bg-color-sc);
}


.bg-pr{
  background-color: #313740!important;
  background-color: var(--bg-color-pr)!important;
}
.bg-sc{
  background-color: #55ba18!important;
  background-color: var(--bg-color-sc)!important;
}
.hover\:bg-pr:hover{
  background-color: #313740!important;
  background-color: var(--bg-color-pr)!important;
}
.hover\:bg-sc:hover{
  background-color: #55ba18!important;
  background-color: var(--bg-color-sc)!important;
}


.clr-yellow{
  color: rgb(244 183 35);
  color: var(--yellow);
}
.clr-green{
  color: rgb(85 186 24);
  color: var(--green);
}
.clr-blue{
  color: rgb(89 203 255);
  color: var(--blue);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.navbar{
  z-index: 1;
}
.navbar.sticky{
  position: fixed!important;
  z-index: 99;
  width: 100vw;
  top: 0;
  left: 0;
}
.navbar.sticky,
.navbar.sticky .navbar{
  padding-top: 0px;
  padding-bottom: 0px;
  
}
.navbar-wrapper{
  height: auto;
}

.navbar-menu.is-active{
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.navbar-menu.is-active .navbar-item h1,
.navbar-menu.is-active .navbar-item big{
  padding: 10px 50px;
  font-size: 18px;
}
.navbar-menu.is-active .navbar-item .dropdown-menu{
  left: 50%;
  transform: translateX(-50%);
  width: 100%!important;
}
.navbar-burger.is-active{
  position: relative;
  z-index: 999;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.quotation-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
  .quotation-bg {    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 330px;

  } 

    .quotation-bg-heading {
      display: flex;
      align-items: center;
      font-size: 3em;
      /* color: #fff; */
      height: inherit;
      text-transform: capitalize;
      
    }

  .quotaion-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 5em 0;
  }
   

    .quotaion-primary-text {
      /* color: map-get($theme-colors, heading-color); */
      font-size: 1.75em;
     

      
    }

    .quotaion-secondary-text {
      /* color: map-get($theme-colors, heading-color); */
      font-size: 1.15em;
      line-height: 2em;

      
    }


  .quotaion-price-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 2em 0;
  }
    .price-heading {
      /* color: map-get($theme-colors, heading-color); */
      font-size: 1.25em;
      
      text-transform: capitalize;
      padding-right: 1em;

      
    }

    .quotaion-price-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ;
      padding: 0.75em 2em;
      cursor: pointer;

    }

      .pdf-icon {
        width: 2em;
        height: 2em;
        /* fill: map-get($theme-colors, error-color); */
        margin-right: 0.5em;

        
      }
      .tab-text {
        /* color: map-get($theme-colors, heading-color); */
        font-size: 1em;
        
        text-transform: capitalize;

        
      }
    
  

  .qoute-link-text {
    /* color: map-get($theme-colors, primary-text-color); */
    border-bottom: 1px solid ;
    margin-left: 0.25em;
  }
    
  

  .quotaion-btn {
    font-size: 1.15em;
    font-weight: normal;
    margin-bottom: 2.5em;
    margin-bottom: 4em;
    padding: 0.75em 2em;

    
  }
  .quotation-contact-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
    .quotaion-primary-text {
      font-size: 1.25em;
      padding-right: 0.2em;
      

     
    }
  


.quotation-table-left-data,
.quotation-table-right-data {
  padding: 1.5em;
  text-align: left;
}


  


.quotation-table-left-data {
  width: 25%;
  /* color: map-get($theme-colors, heading-color); */
  font-size: 20px;
  font-weight: bold;

  
}

.quotation-table-right-data {
  /* color: map-get($theme-colors, heading-color); */
  font-size: 20px;
  width: 25%;

  
}

.quotation-table-container {
  border-collapse: collapse;
  width: 100%;
  background-color: #f5f5f5;
}

.quotation-table-row 
  :nth-child(2n) {
    /* border-top: 1px solid ; */
    border-bottom: 1px solid ;
  
}
.firstPage{
	height:1200px;
	background-position: top;
	background-repeat: no-repeat;
	
	}
	.container{
		page-break-after: always;
	}
	.container-fluid{
		page-break-after: always;
	}
  .heading{
    font-weight:bold;
     font-family:Arial;
      font-size:21.8px; color:#23648c
  }
  .Contents{
    font-family:Arial;
     font-size:18.4px;
      color:#000000
  }
  .hovergreencolor:hover{
    color:#8bc240 !important;
    font-weight:bold !important;

  }
  .roundButton{
    border-radius: 18px !important;
  }

  .button-disabled{
    pointer-events: none;
    cursor: not-allowed;
    opacity: .5;
  }
  .button-disabled[disabled]{
    background-color: #313740;
    background-color: var(--bg-color-pr);
  }
  .custom-button{
    background-color: #313740;
    background-color: var(--bg-color-pr);
    color: #ffffff;
    padding: 0.5em 2em;
    border: 0px;
    border-radius: 5px;
  }
  .custom-button[disabled]{
    opacity: 0.5;
    pointer-events: none;
  }
  .custom-button:hover{
    cursor: pointer;
    background-color: #414751;
    background-color: var(--bg-color-pr-light);
  }
  .custom-button:active{
    transform: scale(0.93);
  }
  .custom-button:focus{
    outline: none;
  }
  .hidden-input{
    display: none;
  }
.buttons-po{
  display: flex;
  justify-content: right;
  grid-gap: 20px;
  gap: 20px;
  padding: 5px 10px;
}
.button-po{
  padding: 0px;
  border: none;
  background-color: transparent;
  width: 35px;
  height: 35px;
}
.button-po img{
  width: 100%;
  max-width: 100%;
}

/* mx modal START */
.mx-modal-bg{
  position: fixed;
  z-index: 99;
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #00000050;
  display: grid;
  place-items: center;
}
.mx-modal-inner{
  position: relative;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: calc(100% - 25px);
  max-width: 1000px;
}
.mx-modal-title{
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #e5e5e5;
}
.mx-modal-close-icon{
  position: absolute;
  font-size: 25px;
  top: 15px;
  right:15px;
  cursor: pointer;
}
.mx-modal-items{
  margin-top: 20px;
}
/* mx modal END */



@keyframes animated-backdrop{
  70%{
    transform: scale(1.5);
    opacity: 0;
  }
  100%{
    transform: scale(1.5);
    opacity: 0;
  }
}


.hamburger-icon{
  height: 3rem;
  width: 3rem;
}



.main-content{
  background-color: #f4f7f6;
  background-color: var(--bg-color);
  height: 100vh;
  max-height: 100vh;
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-color: #ffffff #e5e5e5;
}
.main-content {
  --scrollbarBG: #ffffff;
  --thumbBG: #e5e5e5;
}
.main-content::-webkit-scrollbar {
  width: 15px;
}
.main-content {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.main-content::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.main-content::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.mx-table-button{
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 25px;
  border: 0px;
  background-color: transparent;
  color: #afafaf;
  color: var(--text-gray);
  cursor: pointer;
}
.mx-table-button:hover{
  color: #414751;
  color: var(--bg-color-pr-light);
}
.mx-table-button i{
  font-size: inherit;
}


.mx-scrollable-table-wrap .table-head .table_filter{
  width: 58%!important;
  margin-bottom: 15px;
  margin-top: 15px;
}
.mx-scrollable-table-wrap .table-head .table_filter .form-control{
  padding: 0.5em 1em;
  border-radius: 5px;
  margin-top: 3px;
  font-size: 18px;
}
.mx-scrollable-table-wrap .asrt-table-body{
  margin-top: 0px!important;
}
.mx-table-head{
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  top: 0px;
  background-color: #ffffff;
}
@media(max-width:1200px){
  .mx-table{
    min-width: 1200px;
  }
  .mx-scrollable-table-wrap{
    overflow: scroll;
  }
}
.mx-subservice-title{
  border: 1px solid #b5b5b5;
  background-color: #ffffff;
  box-shadow: 5px 5px 5px #0002;
  padding: 0.8em 1em;
  font-weight: 600;
  color: #151515;
  font-size: 20px;
}
.mx-subservice-holder{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 20px;
}
@media(max-width:1200px){
  .mx-subservice-holder{
    grid-template-columns: repeat(2, 1fr);
  }
}
@media(max-width:767px){
  .mx-subservice-holder{
    grid-template-columns: repeat(1, 1fr);
    padding: 0px;
  }
}
.mx-services-bg{
  background-color: #ffffff;
  border: 1px solid #b5b5b5;
  padding: 20px;
  margin: 50px 0px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px #0003;
}
.mx-checkbox:focus{
  outline: none;
  background-color: #aee68450;
}
.mx-checkbox{
  text-align: left;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  margin: 15px 10px;
  border: 1px solid #d5d5d5;
  border-top: 2px solid #80c44d;
  box-shadow: 0px 15px 10px -10px #0003;
  background-color: #ffffff;
  position: relative;
  isolation: isolate;
}
.mx-checkbox:hover{
  cursor: pointer;
}
.mx-checkbox:hover,
.mx-checkbox.checked{
  border-color: #80c44d;
}
.mx-checkbox::before{
  content: "";
  position: absolute;
  pointer-events: none;
  background-color: #aee684;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0px;
  transform-origin: left;
  transform: scalex(0);
  z-index: -1;
  transition: transform 225ms;
}
.mx-checkbox.checked::before{
  transform: scalex(1);
}
.mx-checkbox .mx-checkbox-icon{
  height: 20px;
  width: 20px;
  display: grid;
  place-items: center;
  font-size: 12px;
  font-weight: 800;
  position: absolute;
  left: 100%;
  bottom: 100%;
  transform: translate(-50%, 50%);
  background-color: #fff;
  border: 2px solid #80c44d;
  border-radius: 50%;
}
.mx-checkbox input[type="checkbox"]{
  display: none;
}














/* Temp fix */
.OT_ModalDialog{
  display: none!important;
}

.card .body {
  font-size: 14px;
  padding: 20px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
  background: #fff;
  color: #616161;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
}
.card .body:hover{
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
}

.l-green {
  background: linear-gradient(45deg, #9ce89d, #cdfa7e) !important
}

.l-pink {
  background: linear-gradient(45deg, pink, #fbc1cb) !important
}

.l-turquoise,.total_earnings .chart div {
  background: linear-gradient(45deg, #00ced1, #08e5e8) !important;
  color: #fff !important
}

.l-cyan {
  background: linear-gradient(45deg, #49cdd0, #00bcd4) !important;
  color: #fff !important
}

.l-khaki {
  background: linear-gradient(45deg, khaki, #fdf181) !important
}

.l-coral {
  background: linear-gradient(45deg, #f08080, #f58787) !important;
  color: #fff !important
}

.l-salmon {
  background: linear-gradient(45deg, #ec74a1, #fbc7c0) !important;
  color: #fff !important
}

.l-blue {
  background: linear-gradient(45deg, #72c2ff, #86f0ff) !important;
  color: #fff !important
}

.l-seagreen {
  background: linear-gradient(45deg, #8ed8ec, #85f7b5) !important;
  color: #fff !important
}

.l-amber,.w_calender span+span,.w_calender em {
  background: linear-gradient(45deg, #fda582, #f7cf68) !important;
  color: #fff !important
}

.l-blush {
  background: linear-gradient(45deg, #dd5e89, #f7bb97) !important;
  color: #fff !important
}

.l-parpl,.total_earnings .chart.expense div {
  background: linear-gradient(45deg, #a890d3, #edbae7) !important;
  color: #fff !important
}

.l-slategray {
  background: linear-gradient(45deg, #708090, #7c8ea0) !important;
  color: #fff !important
}

.l-dark {
  background: linear-gradient(45deg, #555, #333) !important;
  color: #fff !important
}

.xl-pink {
  background: #f9edee !important
}

.xl-turquoise {
  background: #bdf3f5 !important
}

.xl-khaki {
  background: #f9f1d8 !important
}

.xl-parpl {
  background: #efebf4 !important
}

.xl-salmon {
  background: #ffd4c3 !important
}

.xl-blue {
  background: #e0eff5 !important
}

.xl-seagreen {
  background: #ebf2e8 !important
}

.xl-slategray {
  background: #eceeef !important
}

.bg-black {
  background: #22252b
}

.btn-white {
  background-color: #fff;
  color: #000
}



.card-plain {
  border-radius: .55rem;
  padding-bottom: .7rem;
  max-width: 330px;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 10px 25px 0px rgba(0,0,0,0.3)
}

.card-plain.card-plain {
  margin: 0 auto
}

.card-plain .header img {
  width: 80px;
  border: 3px solid #fff;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.5)
}

.card-plain .footer {
  padding: 15px 0
}
.theme-black .authentication{
  background: #313740 !important;
  background: var(--bg-color-pr) !important;
}
.container>.content-center{
  min-height: 100vh;
  /* min-width: 100vw; */
  display: grid;
  place-items: center;
}
.authentication .company_detail {
  color: #fff;
}
.m-b-40, .authentication .company_detail .logo{
  margin-bottom: 40px;
}
.authentication .company_detail h3{
  font-weight: 300;
  font-size: 2rem;
  text-transform: uppercase;
  opacity: 0.7;
  letter-spacing: 2px;
}
.authentication .company_detail p {
  opacity: 0.6;
  letter-spacing: 1px;
  font-weight: 300;
}
.footer {
  padding: 24px 0;
}
.footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
.footer ul li{
  display: inline-block;
}
.footer ul li:first-child a{
  padding-left: 0px;
}
.footer ul li a {
  color: inherit;
  padding: 0.5rem;
  font-size: .93em;
  text-transform: uppercase;
  text-decoration: none;
}
.footer ul li a .zmdi{
  display: inline-block;
  font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
  font-size: 18px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;

}
.authentication .company_detail{
  text-align: left;
}
.authentication .company_detail .logo {
  margin-bottom: 40px;
}
.authentication .company_detail .logo img {
  width: 280px;
  vertical-align: top;
}
.authentication .company_detail .social_link li{
  display: inline-block;
}

@media screen and (max-width: 992px){
  .authentication .company_detail {
      text-align: center;
      margin-bottom: 30px;
  }
  .authentication .company_detail *{
    text-align: inherit;
  }
}


.dot-loader{
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  margin: 15px 0px;
}
.dot-loader-dot{
  height: 11px;
  width: 11px;
  background-color: #afafaf;
  background-color: var(--text-gray);
  border-radius: 50%;
}
.dot-loader-dot:nth-child(1){
  animation: dot-loader-1 linear infinite 2s;
}
.dot-loader-dot:nth-child(2){
  animation: dot-loader-2 linear infinite 2s;
}
.dot-loader-dot:nth-child(3){
  animation: dot-loader-3 linear infinite 2s;
}

@keyframes dot-loader-1{
  0%{
    transform: scale(1);
  }
  10%{
    transform: scale(1.5);
  }
  20%{
    transform: scale(1);
  }
}


@keyframes dot-loader-2{
  0%{
    transform: scale(1);
  }
  20%{
    transform: scale(1);
  }
  30%{
    transform: scale(1.5);
  }
  40%{
    transform: scale(1);
  }
}


@keyframes dot-loader-3{
  0%{
    transform: scale(1);
  }
  20%{
    transform: scale(1);
  }
  30%{
    transform: scale(1);
  }
  40%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.5);
  }
  60%{
    transform: scale(1);
  }
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #222;
    opacity: 0.8;
    z-index: 10;
  }
  #loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #13db13;
    animation: spin 2s linear infinite;
  }
  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #062888;
    animation: spin 3s linear infinite;
  }
  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #ffff;
    animation: spin 1.5s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

.lds-facebook {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 10%;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #cef;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
.iconStyle {
    font-size: 2rem;
    padding: 22px;
    color: #fff;
    background: #1fb9d8;
    box-shadow: 0 14px 26px -12px rgba(17, 17, 17, 0.42),0 4px 23px 0px rgba(187, 32, 32, 0.12),0 8px 10px -5px rgba(46, 46, 51, 0.2) !important;
    border-radius: 100px;
    margin-top: -40px;
    
}
.spinning{
    animation: loaderSpin 1000ms linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
}
.spinning.slow{
    animation: loaderSpin 5000ms linear infinite;
}
.spinning.bigger{
    font-size: 50px;
}

@keyframes loaderSpin {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}
.counter-wrap{
    display: flex;
    align-items: center;
    margin: 15px 0px;
    grid-gap: 10px;
    gap: 10px;
}
 .counter-color{
    height: 10px;
    width: 10px;
    min-height: 10px;
    min-width: 10px;
    border-radius: 50%;
    background-color: #b5b5b5;
}
 .counter-color.primary{
    background-color: #010138;
}
 .counter-color.secondary{
    background-color: #80c44d;
}

.counter-wrap .counter-title{
    font-size: 20px;
    font-weight: 400;
    margin: 0px;
    color: #010138;
    text-align: left;
    display: flex;
    grid-gap: 0.4em;
    gap: 0.4em;
    white-space: nowrap;
}


@media screen and (max-width:768px){
    .counter-main{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        grid-gap: 20px;
        gap: 20px;
    }
    .counter-wrap .counter-color{
        height: 12px;
        width: 12px;
        min-height: 12px;
        min-width: 12px;
    }
    .counter-wrap{
        margin: 0px;
        grid-gap: 5px;
        gap: 5px;
    }
    .counter-wrap .counter-title{
        font-size: 15px;
        
    }
}
.call-support:hover{
    cursor: pointer;
}
.custom-swal-icon{
    border: none!important;
}
.custom-swal-icon img{
    height: 80px;
    width: 80px;
    object-fit: contain;
}
.chart-size > canvas{
    max-width: 90px;
    max-height: 90px;
    min-width: 90px;
    min-height: 90px;
    margin: auto;
}
.animated-text-loader{
    font-size: 30px;
}
.animated-text-loader span:nth-child(1){
    animation: textloader1 1s linear infinite;
}
.animated-text-loader span:nth-child(2){
    animation: textloader2 1s linear infinite;
}
.animated-text-loader span:nth-child(3){
    animation: textloader3 1s linear infinite;
}


@keyframes textloader1{
    0%{
        opacity: 0;
    }
    25%{
        opacity: 1;
    }
    100%{
        opacity: 1;
    }
}
@keyframes textloader2{
    0%{
        opacity: 1;
    }
    25%{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes textloader3{
    0%{
        opacity: 1;
    }
    25%{
        opacity: 1;
    }
    50%{
        opacity: 1;
    }
    75%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}
/* .floating-label { 
    position:relative; 
    margin-bottom:20px; 
  }
  .floating-input , .floating-select {
    font-size:14px;
    padding:4px 4px;
    display:block;
    width:100%;
    background-color: transparent;
    border:none;
    border-bottom:1px solid #757575;
  }
  
  .floating-input:focus , .floating-select:focus {
       outline:none;
       border-bottom:2px solid #5264AE; 
  }
  
  label {
    color:#999; 
    font-size:14px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:5px;
    top:5px;
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  
  .floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
    top:-18px;
    font-size:14px;
    color:#5264AE;
  }
  
  .floating-select:focus ~ label , .floating-select:not([value=""]):valid ~ label {
    top:-18px;
    font-size:14px;
    color:#5264AE;
  }
  .floating-input:focus ~ .bar:before, .floating-input:focus ~ .bar:after, .floating-select:focus ~ .bar:before, .floating-select:focus ~ .bar:after {
    width:50%;
  }
  
  *, *:before, *:after {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
  }
  
  .floating-textarea {
     min-height: 30px;
     max-height: 260px; 
     overflow:hidden;
    overflow-x: hidden; 
  }
  .highlight {
    position:absolute;
    height:50%; 
    width:100%; 
    top:15%; 
    left:0;
    pointer-events:none;
    opacity:0.5;
  }
  .floating-input:focus ~ .highlight , .floating-select:focus ~ .highlight {
    -webkit-animation:inputHighlighter 0.3s ease;
    -moz-animation:inputHighlighter 0.3s ease;
    animation:inputHighlighter 0.3s ease;
  }
  @-webkit-keyframes inputHighlighter {
      from { background:#5264AE; }
    to 	{ width:0; background:transparent; }
  }
  @-moz-keyframes inputHighlighter {
      from { background:#5264AE; }
    to 	{ width:0; background:transparent; }
  }
  @keyframes inputHighlighter {
      from { background:#5264AE; }
    to 	{ width:0; background:transparent; }
  }

  .btn-LoginScreen {
    margin-right: 10px;
    background-color: #000066;
    color: #FFF !important;
  }

  .btn-Link {
    margin-left: 10px;
    margin-right: 10px;
    background-color: #33CC00;
    color: #FFF !important;
  } */
.mx-pagination-main{
    padding: 30px;
}
.mx-page-title{
    font-size: 24px;
}
.mx-pagination{
    font-size: 14px;
}
.mx-pagination .mx-pagination-link:last-child{
    pointer-events: none;
}
.mx-pagination-link{
    color: #80c44d;
}
.mx-pagination-link:hover,
.mx-pagination-link.current{
    color: #0a003f;
    text-decoration: none;
}
.mx-pagination-link + .mx-pagination-link::before{
    content: "/";
    margin: 0px 0.5em;
    color: #80c44d;
}
.chat-holder{
    width: 400px;
    background-color: #222222;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    height: 100%;
    max-height: 100%;
    padding-bottom: 50px;
}
.chat-holder .chat-messages-wrap{
    height: 100%;
    max-height: calc(100% - 20px);
    overflow-y: scroll;
}
.chat-holder .chat-messages-wrap::-webkit-scrollbar{
    width: 0px;
}
.chat-holder .chat-message-typing-wrap{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    border: 1px solid #666666;
    border-left: none;
    border-right: none;
}
.chat-holder .chat-input-wrap{
    padding: 15px;
    background-color: #353535;
    display: flex;
}
.chat-holder .chat-input{
    flex-grow: 1;
    background-color: #282828;
    border: 1px solid #656565;
    padding: 0.5em 1em;
    color: #ffffff;
    min-width: 30px;
}
.chat-holder .chat-send-button{
    padding: 0.2em 2em;
    margin-left: 0.5em;
    background-color: #8bc240;
    border: none;
    color: #000;
}
.chat-holder .chat-attach-file-button{
    font-size: 20px;
    background-color: #444444;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    color: #ffffff;
    margin-right: 5px;
    border: 1px solid #7e7e7e;
    border-radius: 5px;
}
.chat-holder .chat-attach-file-button:hover{
    background-color: #353535;
    cursor: pointer;
}
.chat-holder .chat-send-button:hover{
    cursor: pointer;
    transform: scale(0.95);
    opacity: 0.7;
}

.chat-holder .chat-message-wrap{
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.chat-holder .chat-message-wrap.is-own-message{
    align-items: flex-end;
}
.chat-holder .chat-message-wrap.is-own-message .chat-message-text{
    background-color: #8bc240;
}
.chat-holder .chat-message-text{
    margin: 0px;
    color: #000;
    max-width: 70%;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
    word-break: break-word;
}
.chat-holder .chat-message-sender-name{
    color: #999;
    font-size: 75%;
}

@media(max-width:1500px){
    .chat-holder{
        width: 300px;
    }
}
@media(max-width:992px){
    .chat-holder{
        position: fixed;
        padding: 0px;
        width: 100vw;
        left: 0px;
        top: 0px;
        z-index: 999;
        transform: translateX(100%);
        transition: transform 225ms;
    }
    .chat-holder.is-active{
        transform: translateX(0%);
    }
    .chat-holder .chat-message-typing-wrap{
        bottom: 80px;
    }
    .chat-holder .chat-messages-wrap{
        max-height: calc(100% - 150px);
    }
    .chat-holder .chat-message-text{
        font-size: 20px;
    } 
}
.opentok-main-wrap{
    position: fixed;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    z-index: 99;
    background-color: #353535;
    padding: 10px;
    padding-bottom: 0px;
}
.call-alert{
    position: absolute;
    z-index: 9999999;
    padding: 20px 50px;
    border: 1px solid #fff;
    background-color: #000c;
    color: #fff;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 5px 5px 10px #0005;
    pointer-events: none;
}
.opentok-main-wrap .opentok-left{
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    grid-gap: 15px;
    gap: 15px;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    flex-grow: 1;
    padding-bottom: 80px;
}
.opentok-main-wrap .opentok-left::-webkit-scrollbar{
    width: 10px;
    background-color: #222222;
}
.opentok-main-wrap .opentok-left::-webkit-scrollbar-thumb{
    background-color: #656565;
    margin: 1px;
}
.publisher-streams-holder{
    display: flex;
    align-items: flex-end;
    position: relative;
    margin-top: auto;
}
.publisher-streams-holder .OTPublisherContainer{
    position: absolute;
    bottom: 10px;
    right: 10px;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 20px #0005;
    height: 150px!important;
    width: 200px!important;
}

.subscriber-streams-holder{
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    grid-template-columns: repeat(7, 1fr);
}
.subscriber-streams-holder .waiting-text{
    color: #fff;
    white-space: nowrap;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-80%);
    font-weight: 300;
}
.subscriber-streams-holder .OTSubscriberContainer{
    height: 170px!important;
    width: 100%!important;
}


.opentok-main-wrap .controls-holder{
    position: fixed;
    left: 0;
    bottom: 0;
    width: calc(100% - 408px);
    min-height: 50px;
    background-color: #424242;
    display: flex;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;
    padding: 10px;
    box-shadow: 0px 5px 20px #0005;
}
.opentok-control-button{
    height: 50px;
    width: 50px;
    font-size: 20px;
    background-color: #d5d5d5;
    box-shadow: 5px 5px 10px #0002;
    color: #000;
    border-radius: 50%;
    border: 1px solid #f0f0f0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 225ms;
}
.opentok-control-button img{
    max-width: calc(100% - 25px);
}
.opentok-control-button.button-off{
    opacity: 0.3;
}
.opentok-control-button:hover{
    transform: scale(1.1);
}
.opentok-control-button.end-call{
    background-color: #e71a1a;
    color: #ffffff;
}
.opentok-control-button.toggle-chat{
    display: none;
}
.screen-share-wrap{
    flex-grow: 1;
    height: 70vh;
    position: relative;
}
.screen-share-wrap .banner-image{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.screen-share-wrap > div{
    height: 100%;
}
.subscriber-streams-holder .custom-subscriber-wrap{
    position: relative;
    overflow: hidden;
}
.subscriber-streams-holder .custom-subscriber-wrap .custom-subscriber-name{
    position: absolute;
    left: 0px;
    text-align: center;
    bottom: 0px;
    color: #f0f0f0;
    width: calc(100% - 0.4em);
    padding: 0.4em 0.2em;
    background-color: #0002;
    z-index: 999;
    transform: translateY(100%);
    transition: transform 300ms;
}
.subscriber-streams-holder .custom-subscriber-wrap:hover .custom-subscriber-name{
    transform: translateY(0%);
}
.screen-share-wrap .OTSubscriberContainer,
.screen-share-wrap .OTPublisherContainer{
    position: absolute;
    z-index: 99;
    top: 0px;
    left: 0px;
    width: 100%!important;
    height: 100%!important;
}

/* .screen-share-wrap .OTSubscriberContainer .OT_video-element{
    object-fit: cover;
} */


/* small laptops Responsive */
@media(max-width:1500px){
    .subscriber-streams-holder{
        grid-template-columns: repeat(4, 1fr);
    }
    .screen-share-wrap{
        height: 64vh;
    }
    .opentok-main-wrap .controls-holder{
        width: calc(100% - 308px);
    }
    .publisher-streams-holder .OTPublisherContainer{
        height: 100px!important;
        width: 150px!important;
    }
    .screen-share-wrap .OTSubscriberContainer, .screen-share-wrap .OTPublisherContainer{
        height: 100%!important;
        width: 100%!important;
    }
}

/* Tablet Responsive */

@media(max-width:992px){
    .opentok-main-wrap .controls-holder{
        width: 100vw;
        padding: 15px 0px;
        z-index: 9999;
    }
    .opentok-control-button.toggle-chat{
        display: flex;
    }
    .publisher-streams-holder{
        margin-bottom: 35px;
    }
    
}
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #222;
    opacity: 0.8;
    z-index: 10;
  }
  #loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #13db13;
    animation: spin 2s linear infinite;
  }
  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #062888;
    animation: spin 3s linear infinite;
  }
  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #ffff;
    animation: spin 1.5s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.mx-progress-main{
  padding: 0px 5px;
}
.mx-progress-main.center{
    text-align: center;
}
.mx-progress-main.left{
    text-align: left;
}
.mx-progress-main.right{
    text-align: right;
}
.mx-progress-wrap{
  width: 100%;
  height: 3px;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 2px #0005;
  overflow: hidden;
}
.mx-progress-bar{
  height: 100%;
  background-color: #959595;
}
.mx-progress-number{
  font-size: 12px;
  font-weight: 700;
}
.mx-team-main{
    display: flex;
}
.mx-team-tooltip{
    position: absolute;
    z-index: 99;
    color: #fff;
    background-color: #151515;
    padding: 5px 10px;
    font-size: 14px;
    bottom: calc(100% + 12px);
    left:50%;
    transform: translateX(-50%);
    white-space: nowrap;
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 10px #0005;
    display: none;
}
.mx-team-tooltip::after{
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #151515;
}
.mx-team-image:hover .mx-team-tooltip,
.mx-team-noimage:hover .mx-team-tooltip{
    display: block;
}
.mx-team-image,
.mx-team-noimage{
    height: 30px;
    width: 30px;
    position: relative;
}
.mx-team-image:nth-child(even) .mx-team-noimage{
    background-color: var(--blue);

}
.mx-team-noimage{
    font-size: 12px;
    background-color: #80c44d;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    color: #0a003f;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 5px #0005;
    border-radius: 50%;
}
.mx-team-image:not(:first-child){
    margin-left: -10px;
}
.mx-team-image img{
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 5px #0005;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
/* Dashboard styles */


.mx-details-head{
    cursor: pointer;
    margin-bottom: 15px;
    background-color: #ffffff;
    padding: 0.5em 1em;
    border-radius: 5px;
    font-weight: 600;
    color: #151515;
    font-size: 16px;
}
.mx-details-body{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
    gap: 15px;
    margin-bottom: 30px;
}
.mx-details-scrollable{
    max-height: 35vh;
    overflow-y: auto;
}
.mx-details-notes{
    width: 100%;
    padding: 5px 10px;
    margin: 0px 5px;
    border-radius: 5px;
    background-color: var(--bg-color-pr);
    color: var(--white);
}
.mx-details-notes i{
    margin-right: 12px;
}
.mx-details-info{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-right: 5px;
    font-size: 20px;
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.mx-details-info span{
    font-size: 14px;
}
.mx-details-info >*{
    padding: 5px 10px;
    white-space: nowrap;
    background-color: #252525;
    color: #f0f0f0;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    position: absolute;
    bottom: calc(100% + 5px);
    right: 0%;
    display: none;
}
.mx-details-info:hover >*{
    display: block;
}
.mx-details-inner{
    width: calc(25% - 15px);
    padding: 0px 15px;
    background-color: #ffffff;
}
.mx-details-inner.align-self-start{
    align-self: flex-start;
}
.mx-details-title{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    margin-top: 0.8em;
    font-weight: 600;
}
.mx-step .mx-step-status{
    position: relative;
    background-color: #fff;
}
.mx-step .mx-step-status-line{
    height: 100%;
    width: 2px;
    background-color: currentColor;
    position: absolute;
    top: 5px;
    left: 20px;
}
.mx-step{
    margin-top: 10px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    color: rgb(123, 123, 123);
    position: relative;
}
.mx-step.in-progress{
    color: #ffbf13;
}
.mx-step.completed{
    color: #80c44d;
}
.mx-step.in-progress .mx-step-status i{
    animation: spin 6000ms linear infinite;
}
.mx-step-status{
    font-size: 20px;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid currentColor;
}
.mx-step-desc{
    color: #252525;
}
.mx-step-detail{
    color: #858585;
    font-size: 95%;
}




/* @media(max-width:1600px){
    .mx-details-body{
        flex-wrap: wrap;
    }
    .mx-details-inner{
        width: calc(33% - 15px);
    }
} */
@media(max-width:1600px){
    .mx-details-body{
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .mx-details-inner{
        width: calc(50% - 15px);
    }
}
@media(max-width:767px){
    .mx-details-inner{
        width: 100%;
    }
}
.mx-chat-widget{
    max-height: 35vh;
    height: 100%;
    margin: 10px 0px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: calc(100% - 56px);
}
.mx-chat-widget-message{
    max-width: 80%;
    margin-right: auto;
}
.mx-chat-widget-message-text{
    margin-top: 5px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #b5b5b5;
    word-break: break-word;
    background-color:#f0f0f0;
    color: #000;
}
.mx-chat-widget-message-date{
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    background-color: var(--white);
}
.mx-chat-widget-message-line{
    flex-grow: 1;
    border-top: 1px solid #d5d5d5;
}
.mx-chat-widget-message-time{
    display: block;
    text-align: right;
    font-size: 10px;
    font-weight: 700;
    color: #353535;
    letter-spacing: 1px;
}
.mx-chat-widget-message.is-own{
    margin-left: auto;
    margin-right: 0px;
}
.mx-chat-widget-message.is-own .mx-chat-widget-message-text{
    background-color: #80c44d;
    border-color: transparent;
}
.mx-chat-widget-message.is-own .mx-chat-widget-message-name{
    text-align: right;
}
.mx-chat-widget-message-name{
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
}
.mx-chat-widget-send{
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
    background-color: #ffffff;
    padding: 10px;
    padding-bottom: 0px;
    margin-top: auto;
    display: flex;
}
.mx-chat-widget-send input{
    padding: 0.3em;
    font-size: 14px;
    line-height: 1em;
    background-color: #ffffff;
    width: 100%;
}
.mx-chat-widget-send button{
    padding: 0.3em 1.5em;
    background-color: #80c44d;
    cursor: pointer;
}
.mx-chat-widget-send input,
.mx-chat-widget-send button{
    border: 1px solid #b5b5b5;
}
.mx-engineer{
    margin: 15px 0px;
    display: grid;
    grid-template-columns: 0fr 1fr 0fr 0fr;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    align-items: center;
}
.mx-engineer-status{
    height: 10px;
    width: 10px;
    background-color: #b5b5b5;
    border-radius: 50%;
}
.mx-engineer.online .mx-engineer-status,
.mx-engineer.online .mx-engineer-call{
    background-color: #80c44d;
}

.mx-engineer-info,
.mx-engineer-call{
    border: none;
    border-radius: 1em;
    height: 30px;
    width: 30px;
    background-color: #b5b5b5;
    color: #0a003f;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mx-engineer-info{
    background-color: transparent;
    position: relative;
    font-size: 30px;
    color: var(--text-gray);
}
.mx-engineer-info-main{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 12px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    background-color: #252525;
    color: #f0f0f0;
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translate(-10px,-50%);
    box-shadow: 0px 0px 5px #0004;
    display: none;
    pointer-events: none;
}
.mx-engineer-info-main::after{
    content: "";
    position: absolute;
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #252525;
    width: 0;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
}
.mx-engineer-info-row{
    white-space: nowrap;
    margin: 0.6em 10px;
}
.mx-engineer-info:hover .mx-engineer-info-main{
    display: block;
}

.mx-engineer-call:hover{
    cursor: pointer;
}
.mx-report-dropdown-head{
    margin-top: 10px;
    border: 1px solid #b5b5b5;
    border-radius: 5px;
    background-color: #ffffff;
    display: block;
    width: 100%;
    padding: 0.5em 1em;
    text-align: left;
}
.mx-report{
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.5em 0em;
    font-size: 16px;
}
.mx-report > span{
    font-size: 14px;
    font-weight: 300;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    padding: 0px;
    overflow: hidden;
}
.mx-report > div {
    display: flex;
    align-items: center;
}
.mx-report-dropdown-child{
    margin-top: 10px;
}
.mx-report-download-all{
    padding-bottom: 5px;
    background-color: var(--white);
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
}
.mx-report-download-all-button{
    width: 100%;
    border: none;
    background-color: var(--bg-color-pr);
    color: var(--white);
    border-radius: 5px;
    padding: 0.3em 1em;
    cursor: pointer;
}

.mx-sidebar-overlay{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 90;
}
.mx-sidebar-overlay:hover{
    cursor: pointer;
}
.mx-sidebar-outer{
    display: flex;
    background-color: var(--bg-color-pr);
}
.mx-sidebar-fixed{
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.mx-sidebar-fixed ul{
    margin: 0px;
}
.mx-sidebar-fixed__brand{
    width: 20px;
    height: 20px;
    object-fit: contain;
}
.mx-sidebar-fixed__icons{
    padding: 0px;
    color: var(--white);
}
.mx-sidebar-fixed__icon{
    cursor: pointer;
    display: grid;
    place-items: center;
    font-size: 18px;
    padding: 10px;
    color: var(--text-gray);
}
.mx-sidebar-fixed__icon i{
    position: relative;
}
.mx-sidebar-fixed__icon:hover{
    color: var(--text-gray);
    text-decoration: none;
}
.mobile-icon{
    display: none;
}








.mx-sidebar-slideable{
    padding: 0px 20px;
    border-radius: 5px;
    width: 290px;
    height: calc(100vh - 20px);
    margin: 10px;
    background-color: var(--bg-color);
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    transition: transform 225ms 0ms, visibility 0ms 225ms;
    transform: translateX(calc(-100% - 20px));
    visibility: hidden;
}
.mx-sidebar-slideable.sidebar-active{
    transform: translateX(20%);
    transition: transform 225ms 0ms, visibility 0ms 0ms;
    visibility: visible;
}
.mx-sidebar-card .mx-sidebar-card-header{
    color: var(--text-color);
    padding: 20px 0;
    position: relative;
    box-shadow: none;
}
.mx-sidebar-card .mx-sidebar-card-header h2 {
    font-size: 16px;
    color: #212121;
    position: relative;
}
.mx-sidebar-card .mx-sidebar-card-body{
    font-size: 14px;
    padding: 20px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border-radius: 0.55rem;
    background: var(--white);
    color: var(--text-gray);
    font-weight: 400;
    transition: all 0.2s ease-in-out;
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.menu a{
    display: block;
    border-radius: 0.1875rem;
    line-height: 24px;
    text-transform: uppercase;
    text-align: left;
    transition: all .3s;
    padding: 15px;
    text-decoration: none;
    color: var(--bg-color-sc);
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.menu a:hover{
    background-color: var(--bg-color-pr);
    color: var(--white);
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.menu a i{
    margin-right: 15px;
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.messages .media{
    display: flex;
    align-items: flex-start;
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.messages a{
    text-decoration: none;
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.messages .media .media-object{
    width: 36px;
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.messages li{
    margin-bottom: 20px;
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.messages .media .media-body{
    margin-left: 10px;
    color: var(--text-color);
    font-size: 14px;
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.messages .media .media-body .name .time{
    float: right;
    color: var(--text-gray);
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.messages .media .media-body .message{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 165px;
}











.mx-sidebar-main{
    width: 230px;
    padding: 10px;
    flex-shrink: 0;
    height: 100vh;
    overflow-y: auto;
    background-color: var(--bg-color);
}
.mx-sidebar-main__profile-wrap{
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid #eee;
}
.mx-sidebar-main__profile-image{
    height: 80px;
    width: 80px;
    border: 3px solid #ffffff;
    border-radius: 50%;
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 50%);
}
.mx-sidebar-main__profile-image.smaller{
    height: 40px;
    width: 40px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.mx-sidebar-main__profile-name{
    margin: 0.6em 0px;
}
.mx-sidebar-main__profile-email{
    font-size: 15px;
    margin: 0.6em 0px;
}
.mx-sidebar-logo{
    margin-bottom: 110px;
    position: relative;
}
.mx-sidebar-logo a,
.mx-sidebar-logo img{
    display: block;
}
.mx-sidebar-logo img{
    height: 79px;
    width: auto;
    object-fit: contain;
    padding: 0px 15px;
    margin: auto;
}
/* .mx-sidebar-collapse,
.mx-menu-collapse{
    position: absolute;
    z-index: 1;
    right: 0%;
    top: 125%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    border: none;
    border-right: 0px;
    border-radius: 5px 0px 0px 5px;
    background-color: #ffffff;
    color: #000000;
}
.mx-sidebar-collapse:hover,
.mx-menu-collapse:hover{
    cursor: pointer;
    box-shadow: 0px 0px 5px #0005;
}
.mx-sidebar-collapse:focus,
.mx-menu-collapse:focus{
    outline: none;
}
.mx-sidebar-collapse.collapsed{
    width: calc(100% - 10px);
    left: 5px;
    border-radius: 5px;
}
.mx-menu-collapse{
    display: none;
} */


.mx-sidebar-navigation-wrap{
    font-size: 15px;
    padding: 15px 0px;
}
.mx-sidebar-navigation.active .mx-sidebar-navigation-icon{
    color: var(--text-gray);

}
.mx-sidebar-navigation-icon{
    font-size: 16px;
}
.mx-sidebar-navigation-icon.icon-big{
    font-size: 20px;
}
.mx-sidebar-navigation{
    padding: 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    color: var(--text-color);
}
.mx-sidebar-navigation:hover,
.mx-sidebar-navigation:focus{
    color: var(--text-color);
    text-decoration: none;
}
.mx-sidebar-navigation.active{
    color: var(--white);
    background-color: var(--bg-color-pr);
}
.mx-sidebar-hamburger{
    font-size: 30px;
    position: fixed;
    z-index: 9;
    top: 12px;
    left: 30px;
    display: none;
}

.notify{
    position: absolute;
    bottom: 100%;
    left: 100%;
}
.heartbit{
    background-color: var(--text-gray);
    position: absolute;
    height: 25px;
    width: 25px;
    top: -10px;
    right: -16px;
    z-index: 10;
    border-radius: 50%;
    animation: heartbit 1s ease-out infinite;
}
.notify .point{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--white);
    position: absolute;
    z-index: 11;
    left: 100%;
    top: 0px;
}
@keyframes heartbit{
    0% {
        transform: scale(0);
        opacity: 0
    }

    25% {
        transform: scale(0.1);
        opacity: .1
    }

    50% {
        transform: scale(0.5);
        opacity: .3
    }

    75% {
        transform: scale(0.8);
        opacity: .5
    }

    100% {
        transform: scale(1);
        opacity: 0
    }
}
@media(max-width:1200px){
    .mx-sidebar-main{
        position: fixed;
        z-index: 99;
        top: 0%;
        left: 0%;
        transition: transform 225ms 0ms, visibility 0ms 225ms;
        transform: translateX(-100%);
        visibility: hidden;
    }
    .mx-sidebar-main.sidebar-active{
        border-radius: 5px;
        transform: translateX(25%);
        transition: transform 225ms 0ms, visibility 0ms 0ms;
        visibility: visible;
        box-shadow: 0px 0px 20px #0005;
    }
    .mobile-icon{
        display: block;
    }
    .mx-menu-collapse{
        display: block;
    }
    .mx-sidebar-hamburger{
        display: block;
    }
}
@media(max-width:768px){
    .mx-sidebar-main.sidebar-active{
        width: calc(100% - 80px);
        height: calc(100vh - 30px);
        top: 15px;
        left: revert;
        right: 15px;
        transform: translateX(0px);
    }
    .mx-sidebar-slideable.sidebar-active{
        width: calc(100% - 80px);
        transform: translateX(0%);
        left: revert;
        right: 5px;
    }
}
.mx-topbar-main{
    display: flex;
    justify-content: right;
    align-items: center;
    min-height: 70px;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    padding: 12px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px #0002;
    z-index: 2;
}
.mx-topbar-link{
    color: #010138;
}
.mx-topbar-icon{
    margin: 0px 10px;
    font-size: 22px;
    position: relative;
}
.mx-topbar-icon-badge{
    height: 10px;
    width: 10px;
    position: absolute;
    top: 0px;
    left: 100%;
    border-radius: 50%;
}
.mx-topbar-icon-badge.primary{
    background-color: #80c44d;
}
.mx-topbar-icon-badge.danger{
    background-color: #f00;
    left: 82%;
}
.mx-topbar-link:hover,
.mx-topbar-icon:hover,
.mx-topbar-icon.active{
    color: #80c44d;
}


.mx-dropdown-main{
    position: relative;
    cursor: pointer;
}
.mx-dropdown-children{
    position: absolute;
    top: 100%;
    left: 0%;
    z-index: 1;
    background-color: #ffffff;
    padding: 10px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 100%;
    box-shadow: 0px 0px 10px #0005;
    border-radius: 10px;
}
.mx-profile-photo{

}
.mx-profile-photo img{
    height: 35px;
    width: 35px;
    object-fit: cover;
    object-position: center;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 5px #0009;
    border-radius: 50%;
    margin-left: 10px;
}
.callcontroldiv
{
    position: absolute;
    bottom: 0;
    z-index: 100;
    background:rgba(0,0,0,0.5);
    width:100%;
    justify-content: center;
}
.app-publisher
{
    top:35px;
    right: 10px;
}
.btn{
    margin-right: 10px;
}
/* .callcontroldiv{
    width:100%;
} */
/* .callcontroldiv
{
    display: flex;
    justify-content: center;
    align-items: center;
} */
/* .column {
    display: flex !important;
} */



.equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.equal-height .card-content {
  flex: auto;
}

.ul-Padding{
  padding-left: 0px !important; 
}
.package-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  white-space: nowrap;
}

.message{
  background-color: #ffffff;
}
.message .message-title{
  color: var(--text);
  font-size: 18px;
  margin: 0px;
}
.message .message-header{
  background-color: var(--white);
  border: 1px solid var(--bg-color-pr);
  cursor: pointer;
  margin-bottom: 15px;
  margin-top: 15px;
  box-shadow: 10px 10px 10px #00000010;
}
.message .message-header .btn{
  background-color: var(--bg-color-pr);
  color: var(--white);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0px;
}
.message .columns{
  padding: 10px;
}
.mx-package-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75em 1em;
}
.mx-packages{
  background-color: transparent;
}
  
.custom-card__inner{
    background-color: var(--white);
    position: relative;
    padding: 20px 10px;
    border: 1px solid #f0f0f0;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border-radius: 0.55rem;
    transition: transform var(--transition-speed), box-shadow var(--transition-speed);
    --transition-speed:300ms;
    --color-1:#010138;
    --color-2:#80c44d;
}
.custom-card__inner.has-error{
    border-color: var(--bg-color-pr);
}
.custom-card:hover .custom-card__inner:not(.has-error){
    transform: translateY(-5px) scale(1.02);
    z-index: 99;
    box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
}
.custom-card__price-wrap{
    display: flex;
    font-weight: 700;
    font-size: 30px;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    padding: 0.3em 0px;
}
.custom-card__price{
    text-align: center;
    line-height: 1.25em;
    color: var(--color-1);
}
.custom-card__currency{
    text-align: center;
    color: var(--color-1);
}
.custom-card__quantity-price{
    font-size: 25px;
    font-weight: 700;
}
.added-to-cart-icon{
    position: absolute;
    top: 0px;
    right:0px;
    background-color: var(--bg-color-sc);
    color: var(--white);
    border-radius: 50%;
    font-size: 14px;
    font-weight: 900;
    height: 25px;
    width: 25px;
    display: grid;
    place-items: center;
  }
.custom-card__inner .custom-card__title{
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    min-height: 3em;
    margin: 0px;
    color: var(--bg-color-pr);
    white-space: break-spaces;
    word-break: break-all;
    display: block;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.custom-card__list{
    padding: 0px;
    list-style: none;
    position: relative;
    transition: max-height 300ms;
}
.custom-card__list-item{
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #252525;
    line-height: 2em;
    min-height: 4em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.custom-card__list .custom-card__list-item-wrap:first-of-type{
    padding-right: 40px;
}
.custom-card__list-icon{
    color: #80c44d;
    margin-top: 0.6em;
}
.custom-card__list-dropdown:focus{
    outline: none;
}
.custom-card__list-dropdown{
    position: absolute;
    top: 3px;
    right: 5px;
    background-color: transparent;
    color: var(--bg-color-sc);
    border: 2px solid var(--bg-color-sc);
    border-radius: 5px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-card__list-dropdown i{
    font-weight: 900;
    color: inherit;
}
.custom-card__list-dropdown.rotate i{
    transform: rotateZ(180deg);
}
.custom-card__list-dropdown i{
    transition: transform 225ms;
}
.custom-card__list-dropdown:hover{
    cursor: pointer;
}
.custom-card__list-item-wrap{
    display: flex;
    grid-gap: 1em;
    gap: 1em;
}
.custom-card__button{
    font-size: 12px;
    color: #ffffff;
    background-color: var(--bg-color-pr);
    border: none;
    padding: 0.5em 2em;
    border-radius: 1.5em;
    transition: background-color 225ms;
}
.custom-card__button:hover{
    cursor: pointer;
    background-color: var(--bg-color-sc);
}
.custom-card__cart-indication{
    padding: 0em;
    font-size: 10px;
    color: var(--text-color);
    letter-spacing: 2px;
    font-weight: 600;
    background-color: var(--bg-color-sc);
    text-align: center;
    position: absolute;
    bottom: 0px;
    left: -1px;
    width: calc(100% + 2px);
}
.custom-card__content h5{
    font-size: 14px;
    margin: 1em 0em;
    color: var(--bg-color-sc);
}
.custom-card__quantity-wrap{
    display: flex;
    margin: 6px 0px;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.custom-card__quantity-input{
    width: 3em;
    text-align: center;
    border: 1px solid var(--bg-color-sc);
    padding: 0px;
}
.custom-card__quantity-input:focus{
    outline: none;
}
.custom-card__quantity-divider{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    margin: 15px 0px;
    border: 1px solid #d8d8d8;
    border-left: none;
    border-right: none;
    padding: 10px 0px;
}
.custom-card__content .custom-card__quantity-heading{
    color: var(--text-color);
    position: relative;
    margin: 5px 0px;
    display: inline;
}
.custom-card__quantity-tooltip-wrap{
    position: relative;
}
.custom-card__quantity-tooltip-wrap:hover .custom-card__quantity-tooltip{
    display: block;
}
.custom-card__quantity-tooltip{
    position: absolute;
    z-index: 999;
    background-color: var(--bg-color-pr);
    color: var(--white);
    font-weight: 500;
    font-size: 12px;
    font-family: 'Muli', Arial, Tahoma, sans-serif;
    letter-spacing: 1px;
    padding: 2px 5px;
    border-radius: 5px;
    left: 50%;
    bottom: calc(100% + 10px);
    transform: translateX(-50%);
    box-shadow: 0px 0px 10px #0005;
    /* width: 100%; */
    display: none;
}
.custom-card__quantity-tooltip::after{
    content: "";
    position: absolute;
    top: calc(100% - 1px);
    left: 50%;
    transform: translateX(-50%);
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--bg-color-pr);
}
.custom-card__quantity-button{
    height: 25px;
    width: 25px;
    font-weight: 700;
    border-radius: 50%;
    color: #ffffff;
    background-color: var(--bg-color-pr);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-card__quantity-button:hover{
    cursor: pointer;
    background-color: var(--bg-color-sc);
}
.custom-card__quantity-button[disabled],
.custom-card__quantity-input[disabled]{
    opacity: 0.5;
}
.custom-card__add-to-cart-wrap{
    border-top: 1px solid #d8d8d8;
    padding-top: 15px;
    padding-bottom: 5px;
    margin-top: 10px;
}
.custom-alert-message{
    position: absolute;
    pointer-events: none;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: var(--text-color);
    font-weight: 500;
    border: 2px solid var(--bg-color-pr);
    border-radius: 10px;
    box-shadow: 0px 20px 10px -10px #0004;
    padding: 10px 20px;
    width: calc(100% - 20px);
    text-align: center;
}
.quotation-card-wrap{
    background-color: #ffffff;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 20px #0003;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    padding-bottom: 20px;
    --color-sec: var(--bg-color-pr);
    --color-pri: var(--bg-color-sc);
    --color-third:rgb(232, 185, 95);
}

























.audiowide{
    font-family: audiowide;
}
.color-white{
    color: var(--white);
}
.color-black{
    color: black;
}
.color-1{
    color: var(--color-pri);
}
.color-2{
    color: var(--color-sec);
}
.color-3{
    color: var(--color-third);
}
.bg-1{
    background-color: var(--color-pri);
}
.bg-2{
    background-color: var(--color-sec);
}
.bg-3{
    background-color: var(--color-third);
}
.logo-image{
    width: 60%;
}
.text-center{
    text-align: center;
}
.address{
    margin: 0px;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
}
.quotation-card-wrap .quotation-row-icons{
    display: flex;
    font-size: 16px;
    grid-gap: 10px;
    gap: 10px;
    justify-content: space-between;
    margin-top: 15px;
}
.quotation-card-wrap .quotation-row-icons .quotation-row-icon{
    border-radius: 5px;
    display: block;
    padding: 5px 10px;
    background-color: var(--color-pri);
    color: var(--white);
    box-shadow: 2px 8px 10px -5px #00000038;
    white-space: nowrap;
}
.quotation-card-wrap .quotation-row-icons .quotation-row-icon:hover{
    cursor: pointer;
    color: var(--white);
}
.top-section-wrap{
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid var(--color-sec);
}
.top-section-wrap > *{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.top-section-right{
    background-color: var(--color-pri);
}
.bottom-section-wrap{
    padding: 20px;
    font-size: 16px;
}
.details-wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.details-wrap >*{
    width: 50%;
}
.details-left{
    display: flex;
    justify-content: flex-start;
}
.details-right{
    display: flex;
    justify-content: flex-end;
}
.details-right-heading{
    font-size: 40px;
    letter-spacing: 2px;
    margin: 0.5em 0px;
    margin-top: 0px;
    padding: 0.1em 0px;
    border-bottom: 2px solid var(--color-third);
}
.inner-wrap .details-text{
    margin: 5px 0em;
    padding-right: 50px;
}
.heading-text{
    font-size: 17px;
    font-style: italic;
    color: #666666;
}
.custom-list{
    list-style: none;
    padding: 0px;
}
.custom-list li{
    padding-left: 2em;
    margin: 15px 0px;
    position: relative;
}
.custom-list li::before{
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    background-color: var(--color-third);
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
}
.custom-table-wrap{
    margin: 30px 0px;
    font-size: 14px;
}
.custom-table-row{
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 1fr 1fr 1fr;
    background-color: rgb(240, 240, 240);
}
.custom-table-row:nth-child(even){
    background-color: rgb(224, 228, 229);
}
.custom-table-data + .custom-table-data:not(.custom-table-head){
    border-left: 1px solid var(--color-third);
}
.custom-table-data{
    padding: 5px 10px;
    width: 100%;
    border-left: 1px solid transparent;
}
.custom-table-head{
    font-weight: 700;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.total-wrap{
    font-size: 14px;
}
.total-top{
    border-left: 2px solid var(--color-third);
}
.total-bottom{
    margin: 10px 0px;
}
.total-row{
    border-bottom: 2px solid var(--color-third);
    margin: 0px 10px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    grid-gap: 50px;
    gap: 50px;
}
.total-row + .total-row{
    padding-top: 5px;
}
.total-row:last-child{
    padding-bottom: 0px;
    border-bottom: 0px;
}
.total-bottom .total-row{
    padding: 5px 0px;
}
.terms-title{
    font-size: 12px;
    margin-top: 1.5em;
    border-bottom: 2px solid rgb(213,65,89);
    color: rgb(213, 65, 89);
    padding: 5px 0px;
    max-width: 300px;
}
.quotation-buttons-wrap .button{
    padding: 0.3em 1.5em;
    margin: 0px 10px;
    border-radius: 5px;
    border: none;
    color: var(--white);
}
.button:hover{
    cursor: pointer;
}



.bold{
    font-weight: 600;
}
.flex{
    display: flex;
}
.flex-row{
    flex-direction: row;
}
.flex-column{
    flex-direction: column;
}



































/* Mobile Optimization */

@media(max-width:768px){
    .details-wrap{
        flex-wrap: wrap;
    }
    .details-wrap >*{
        width: 100%;
    }
    .details-right,
    .details-left{
        width: 100%;
    }
    .details-right-heading{
        margin-top: 0px;
    }
    .heading-text{
        padding-bottom: 8px;
    }
    .custom-table-wrap{
        overflow: scroll;
    }
    .address{
        font-size: 16px;
    }
    .logo-image{
        width: 100%;
    }
    .quotation-card-wrap .quotation-row-icons .quotation-row-icon{
        font-size: 13px;
    }
    .quotation-card-wrap .quotation-row-icons{
        justify-content: flex-end;
    }
    .custom-table-data{
        word-break: break-word;
    }
    .inner-wrap{
        width: 100%;
    }
    .mobile-order-1{
        order: 1;
    }
}
.form-row{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    grid-gap: 10px;
    gap: 10px;
    margin: 10px 0px;
}
.form-row >*{
    flex-grow: 1;
}
.form-wrap{
    padding: 15px 10px;
    border: 1px solid #b5b5b5;
    background-color: #ffffff;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    margin: 20px 30px;
    border-radius: 10px;
    width: calc(100% - 20px);
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
.form-wrap .form-title{
    display: block;
    text-align: center;
    font-size: 30px;
    margin-bottom: 1em;
    color: #000066;
}
.form-input-wrap.justify-center{
    display: flex;
    justify-content: center;
}
.form-input-wrap{
    margin: 5px 0px;
}
.form-input-wrap input:not([type="submit"]),
.form-input-wrap textarea,
.form-input-wrap select{
    width: 100%;
    border-radius: 5px;
    border: 1px solid #b5b5b5;
    padding: 0.4em 1em;
    color: #000066;
}
.form-input-wrap input:not([type="submit"]):focus,
.form-input-wrap textarea:focus,
.form-input-wrap select:focus{
    outline: none;
    border-color: #80c44d;
}
/* label{
    position: inherit;
} */
.btn{
    margin: 0px;
}
.panelHeading{    
    border-radius: 6px 6px 0 0;
    color: #363636;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.25;
    padding: 0.75em 1em;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}
.stylingList
{
  padding-left: 30px;
}

  /* Add some padding inside the card container */
.mx-input-wrap{
    --font-size:16px;
    --speed:225ms;
    --border-color:#e3e3e3;
    --text-color:#333;
    --bg: #f0f0f0;
    --color-pri: var(--bg-color-sc);
    --color-pri-light:var(--bg-color-sc-light);
    --color-sec: var(--bg-color-pr);
    --color-sec-light: var(--bg-color-pr-light);
    --color-third:rgb(232, 185, 95);
    position: relative;
    margin: calc(var(--font-size)) 0px;
    width: 100%;
    padding: 0px;
    background-color: transparent;
    border: none;
}
.mx-input-wrap.input-thin{
    --font-size:14px;
}
.mx-input-wrap + .mx-input-wrap{
    margin-top: 0px;
}
.mx-input-wrap.primary{
    position: relative;
    --speed:225ms;
    --border-color:#e3e3e3;
    --text-color: var(--color-pri);
    --bg:var(--color-pri-light);
}
.mx-input-wrap.secondary{
    position: relative;
    --speed:225ms;
    --border-color:#b5b5b5;
    --text-color:var(--color-sec);
    --bg:var(--color-sec-light);
}
.mx-input-wrap input,
.mx-input-wrap textarea,
.mx-input-wrap select{
    font-size: var(--font-size);
    width: 100%;
    max-width: 100%;
    border: 1px solid var(--border-color);
    padding: 0.6em 1em;
    border-radius: 1.5em;
}
.mx-input-wrap input:focus,
.mx-input-wrap textarea:focus,
.mx-input-wrap select:focus{
    outline: none;
    border-color: var(--color-sec-light);
}
.mx-input-wrap select:focus{
    background-color: #ffffff;
}
.mx-input-wrap.animated-label input::-webkit-input-placeholder, .mx-input-wrap.animated-label textarea::-webkit-input-placeholder{
    display: none;
    opacity: 0;
    visibility: hidden;
}
.mx-input-wrap.animated-label input:-ms-input-placeholder, .mx-input-wrap.animated-label textarea:-ms-input-placeholder{
    display: none;
    opacity: 0;
    visibility: hidden;
}
.mx-input-wrap.animated-label input::placeholder,
.mx-input-wrap.animated-label textarea::placeholder{
    display: none;
    opacity: 0;
    visibility: hidden;
}
.mx-input-wrap.animated-label :is(input:focus, input:not(:-ms-input-placeholder)) ~ .label, .mx-input-wrap.animated-label :is(textarea:focus, textarea:not(:-ms-input-placeholder)) ~ .label{
    top: -0.7em;
    left: 0.5em;
    transform: scale(0.85);
    color: var(--color-sec-light);
    background-color: #fff;
    opacity: 1;
}
.mx-input-wrap.animated-label :is(input:focus, input:not(:placeholder-shown)) ~ .label,
.mx-input-wrap.animated-label :is(textarea:focus, textarea:not(:placeholder-shown)) ~ .label{
    top: -0.7em;
    left: 0.5em;
    transform: scale(0.85);
    color: var(--color-sec-light);
    background-color: #fff;
    opacity: 1;
}
.mx-input-wrap .label{
    position: static;
}
.mx-input-wrap.animated-label .label{
    opacity: 0.5;
    transition: var(--speed);
    font-size: var(--font-size);
    font-weight: 600;
    padding: 0px 5px;
    color: var(--color-sec-light);
    position: absolute;
    top: 0.6em;
    pointer-events: none;
    left:1em;
}
.input-right-icon{
    cursor: pointer;
    font-size: 1.4em;
    position: absolute;
    right: 0.7em;
    top: calc(var(--font-size) - 2px);
}
.mx-input-error{
    color: rgb(239, 45, 45);
    font-size: 13px;
    margin-top: 0.2em;
    font-weight: 500;
}
.mx-input-info{
    color: #454545;
    font-size: 13px;
    font-style: italic;
    margin-top: 0.2em!important;
    font-weight: 500;
}

.mx-input-wrap button{
    border: none;
    font-weight: 600;
    border-radius: 1.5em;
    background-color: #f0f0f0;
    color: #111111;
    padding: 0.5em 1.5em;
    transition: 150ms;
}
.mx-input-wrap.mx-large button{
    font-size: 18px;
}
.mx-input-wrap button:hover,
.mx-input-wrap button:focus{
    box-shadow: 5px 5px 10px #0003;
    transform: translateY(-2px);
    cursor: pointer;
}
.mx-input-wrap button:focus{
    outline: none;
}
.mx-input-wrap.mx-centered button{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.mx-input-wrap.mx-left button{
    display: block;
    margin-right: auto;
}
.mx-input-wrap.mx-right button{
    display: block;
    margin-left: auto;
}
.mx-input-wrap.full-width button{
    width: 100%;
}
.mx-input-wrap.primary button{
    background-color: var(--white);
    border:1px solid var(--color-sec);
    color: var(--color-sec);
}

.mx-input-wrap.secondary button{
    background-color: var(--color-sec);
    color: #ffffff;
}
.mx-input-wrap.secondary button:hover{
    background-color: var(--color-sec-light);
}

.form-group .form-control+.input-group-addon, .input-group .form-control+.input-group-addon {
    padding: 10px 18px 10px 0;
    position: absolute;
    right: 0px;
    bottom: 0em;
}

.profile-page .profile-top{
    margin-bottom: 60px;
}
.edit-profile-wrap .header h2{
    font-size: 16px;
    color: #212121;
    position: relative;
}

.edit-profile-wrap .body{
    font-size: 14px;
    padding: 20px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border-radius: 0.55rem;
    background: #fff;
    color: #616161;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
}
.edit-profile-wrap .body:hover {
    box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
    transition: all 0.2s ease-in-out;
}
.user_pic{
    height: 180px;
    width: 180px;
    object-fit: cover;
}
.img-raised{
    box-shadow: 0px 10px 25px 0px rgb(0 0 0 / 30%);
}
.user_pic {
    position: absolute;
    bottom: -70px;
    z-index: 99;
}
.detail {
    margin-left: 250px;
}
.u_name{
    height: 250px;
    margin-bottom: -45px;
    color: var(--text-gray);
}
@media only screen and (max-width: 767px){
    .user_pic {
        position: relative;
        top: 0;
        bottom: auto;
        margin: 0 auto;
    }
    .detail {
        margin: 0;
        padding-top: 15px;
    }
    .u_name{
        height: auto;
        margin: 0px;
    }
    .profile-page .profile-header {
        text-align: center;
    }
}
