.callcontroldiv
{
    position: absolute;
    bottom: 0;
    z-index: 100;
    background:rgba(0,0,0,0.5);
    width:100%;
    justify-content: center;
}
.app-publisher
{
    top:35px;
    right: 10px;
}
.btn{
    margin-right: 10px;
}
/* .callcontroldiv{
    width:100%;
} */
/* .callcontroldiv
{
    display: flex;
    justify-content: center;
    align-items: center;
} */