body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Muli', Arial, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}
.HeadImage
{
  height:auto;
  background-color:blue;
}
.feature-card
{
  height:auto;
}
#CardHeight
{
  height: auto;
}
.container p
{
  text-align: justify;
  margin-top: 20px;
}
ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1 em;
  margin: auto;
  padding-left: 150px;
  text-align: left;
  
}
.col-sm-6 h3 u
{
  text-align: left;
}
.body-text
{
  text-align: justify;
}
.container-fluaid {
  position: relative;
  text-align: center;
  color: white;
}
/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.top-left h1
{
  background-color: rgb(45, 161, 255);
  opacity:0.6;
  color: white;
  border-radius: 8px;
}
/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 18px;
  left: 16px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Paragraph p
{
  text-align: justify;
}
#video2
{
  background: cover;
}
#Palyer
{
  height: 100%;
}
#Table
{
  border:1.5px solid black;
  background-color: #dbdbdb47;
}
#callScreen{
  position: absolute;
    top: 0;
    z-index: 48;
    width:100%;
    height:100%;
    background-color: black;
}
.app-subscriber
  {
    position: absolute;
    top: 0;
    z-index: 50;
}
.app-publisher
  {
    position: absolute;
    top: 100px;
    right: 100px;
    /* z-index: 100; */
    border: 3px solid white;
    border-radius: 3px;
    z-index: 150;
}
/* .NewPageLoader
{
  height: 100%;
  padding: 18%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  background-color: white;
} */
#video{
  position:relative;
  margin:0 auto;
  left:0px;
  right:0px;
  text-align: center;  
  
    background: #fff;
  }
  .close {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 100px;
    background: orange;
    text-align: center;
    padding: 10px;
    display: none;
   
  }
  
  
  .close:hover {
    cursor: pointer;
    background-color: orange;
  }
  
  #video:hover .close {
    display: block;
  }
  .ellips {
    height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p{
    margin-bottom: 0;
  }
  .accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  position: relative;
  background-color: #f4f4f4;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
}

.accordion__button:hover {
    background-color: #ddd;
}

.accordion__button:before {
  
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-bottom: 6px double currentColor;
  border-right: 6px double currentColor;
  transform: rotate(45deg) translateY(-50%);
  right: 1.5em;
  top: 50%;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(225deg) translateY(50%);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
}
.mx-form-bg{
  padding: 1px;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #fafaff;
}
.mx-form-card{
  width: 80%;
  margin: 50px auto;
  background-color: #ffffff;
  box-shadow: 0px -60px 30px -60px #0002,0px 60px 30px -60px #0002, 60px 0px 30px -60px #0002, -60px 0px 30px -60px #0002;
  border-radius: 20px;
  border: 1px solid #e5e5e5;
  padding: 40px 20px;
}
.mx-form-wrap{
  padding: 0px 5%;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
/* .callcontroldiv{
  display: none;
  position: absolute;
  top: 90%;
  z-index: 100;
  background:rgba(0,0,0,0.5);
  width:100%
} */
/* .app-subscriber:hover .callcontroldiv{
  display: flex;
} */
/* .fa.fa-video-camera:after {
  position: absolute;
  content: "";
  left: 1.33em;
  top: 0;
  width: .2em;
  height: 2.5em;
  display: block;
  background: white;
  transform: rotate(38deg);
  border-right: .1em solid black;
} */
