/* label{
    position: inherit;
} */
.btn{
    margin: 0px;
}
.panelHeading{    
    border-radius: 6px 6px 0 0;
    color: #363636;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.25;
    padding: 0.75em 1em;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}
.stylingList
{
  padding-left: 30px;
}

  /* Add some padding inside the card container */