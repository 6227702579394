.mx-topbar-main{
    display: flex;
    justify-content: right;
    align-items: center;
    min-height: 70px;
    position: sticky;
    top: 0px;
    padding: 12px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px #0002;
    z-index: 2;
}
.mx-topbar-link{
    color: #010138;
}
.mx-topbar-icon{
    margin: 0px 10px;
    font-size: 22px;
    position: relative;
}
.mx-topbar-icon-badge{
    height: 10px;
    width: 10px;
    position: absolute;
    top: 0px;
    left: 100%;
    border-radius: 50%;
}
.mx-topbar-icon-badge.primary{
    background-color: #80c44d;
}
.mx-topbar-icon-badge.danger{
    background-color: #f00;
    left: 82%;
}
.mx-topbar-link:hover,
.mx-topbar-icon:hover,
.mx-topbar-icon.active{
    color: #80c44d;
}


.mx-dropdown-main{
    position: relative;
    cursor: pointer;
}
.mx-dropdown-children{
    position: absolute;
    top: 100%;
    left: 0%;
    z-index: 1;
    background-color: #ffffff;
    padding: 10px;
    width: max-content;
    min-width: 100%;
    box-shadow: 0px 0px 10px #0005;
    border-radius: 10px;
}
.mx-profile-photo{

}
.mx-profile-photo img{
    height: 35px;
    width: 35px;
    object-fit: cover;
    object-position: center;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 5px #0009;
    border-radius: 50%;
    margin-left: 10px;
}