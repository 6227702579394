.custom-card__inner{
    background-color: var(--white);
    position: relative;
    padding: 20px 10px;
    border: 1px solid #f0f0f0;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border-radius: 0.55rem;
    transition: transform var(--transition-speed), box-shadow var(--transition-speed);
    --transition-speed:300ms;
    --color-1:#010138;
    --color-2:#80c44d;
}
.custom-card__inner.has-error{
    border-color: var(--bg-color-pr);
}
.custom-card:hover .custom-card__inner:not(.has-error){
    transform: translateY(-5px) scale(1.02);
    z-index: 99;
    box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
}
.custom-card__price-wrap{
    display: flex;
    font-weight: 700;
    font-size: 30px;
    align-items: center;
    gap: 4px;
    padding: 0.3em 0px;
}
.custom-card__price{
    text-align: center;
    line-height: 1.25em;
    color: var(--color-1);
}
.custom-card__currency{
    text-align: center;
    color: var(--color-1);
}
.custom-card__quantity-price{
    font-size: 25px;
    font-weight: 700;
}
.added-to-cart-icon{
    position: absolute;
    top: 0px;
    right:0px;
    background-color: var(--bg-color-sc);
    color: var(--white);
    border-radius: 50%;
    font-size: 14px;
    font-weight: 900;
    height: 25px;
    width: 25px;
    display: grid;
    place-items: center;
  }
.custom-card__inner .custom-card__title{
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    min-height: 3em;
    margin: 0px;
    color: var(--bg-color-pr);
    white-space: break-spaces;
    word-break: break-all;
    display: block;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.custom-card__list{
    padding: 0px;
    list-style: none;
    position: relative;
    transition: max-height 300ms;
}
.custom-card__list-item{
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #252525;
    line-height: 2em;
    min-height: 4em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.custom-card__list .custom-card__list-item-wrap:first-of-type{
    padding-right: 40px;
}
.custom-card__list-icon{
    color: #80c44d;
    margin-top: 0.6em;
}
.custom-card__list-dropdown:focus{
    outline: none;
}
.custom-card__list-dropdown{
    position: absolute;
    top: 3px;
    right: 5px;
    background-color: transparent;
    color: var(--bg-color-sc);
    border: 2px solid var(--bg-color-sc);
    border-radius: 5px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-card__list-dropdown i{
    font-weight: 900;
    color: inherit;
}
.custom-card__list-dropdown.rotate i{
    transform: rotateZ(180deg);
}
.custom-card__list-dropdown i{
    transition: transform 225ms;
}
.custom-card__list-dropdown:hover{
    cursor: pointer;
}
.custom-card__list-item-wrap{
    display: flex;
    gap: 1em;
}
.custom-card__button{
    font-size: 12px;
    color: #ffffff;
    background-color: var(--bg-color-pr);
    border: none;
    padding: 0.5em 2em;
    border-radius: 1.5em;
    transition: background-color 225ms;
}
.custom-card__button:hover{
    cursor: pointer;
    background-color: var(--bg-color-sc);
}
.custom-card__cart-indication{
    padding: 0em;
    font-size: 10px;
    color: var(--text-color);
    letter-spacing: 2px;
    font-weight: 600;
    background-color: var(--bg-color-sc);
    text-align: center;
    position: absolute;
    bottom: 0px;
    left: -1px;
    width: calc(100% + 2px);
}
.custom-card__content h5{
    font-size: 14px;
    margin: 1em 0em;
    color: var(--bg-color-sc);
}
.custom-card__quantity-wrap{
    display: flex;
    margin: 6px 0px;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.custom-card__quantity-input{
    width: 3em;
    text-align: center;
    border: 1px solid var(--bg-color-sc);
    padding: 0px;
}
.custom-card__quantity-input:focus{
    outline: none;
}
.custom-card__quantity-divider{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin: 15px 0px;
    border: 1px solid #d8d8d8;
    border-left: none;
    border-right: none;
    padding: 10px 0px;
}
.custom-card__content .custom-card__quantity-heading{
    color: var(--text-color);
    position: relative;
    margin: 5px 0px;
    display: inline;
}
.custom-card__quantity-tooltip-wrap{
    position: relative;
}
.custom-card__quantity-tooltip-wrap:hover .custom-card__quantity-tooltip{
    display: block;
}
.custom-card__quantity-tooltip{
    position: absolute;
    z-index: 999;
    background-color: var(--bg-color-pr);
    color: var(--white);
    font-weight: 500;
    font-size: 12px;
    font-family: 'Muli', Arial, Tahoma, sans-serif;
    letter-spacing: 1px;
    padding: 2px 5px;
    border-radius: 5px;
    left: 50%;
    bottom: calc(100% + 10px);
    transform: translateX(-50%);
    box-shadow: 0px 0px 10px #0005;
    /* width: 100%; */
    display: none;
}
.custom-card__quantity-tooltip::after{
    content: "";
    position: absolute;
    top: calc(100% - 1px);
    left: 50%;
    transform: translateX(-50%);
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--bg-color-pr);
}
.custom-card__quantity-button{
    height: 25px;
    width: 25px;
    font-weight: 700;
    border-radius: 50%;
    color: #ffffff;
    background-color: var(--bg-color-pr);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-card__quantity-button:hover{
    cursor: pointer;
    background-color: var(--bg-color-sc);
}
.custom-card__quantity-button[disabled],
.custom-card__quantity-input[disabled]{
    opacity: 0.5;
}
.custom-card__add-to-cart-wrap{
    border-top: 1px solid #d8d8d8;
    padding-top: 15px;
    padding-bottom: 5px;
    margin-top: 10px;
}
.custom-alert-message{
    position: absolute;
    pointer-events: none;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: var(--text-color);
    font-weight: 500;
    border: 2px solid var(--bg-color-pr);
    border-radius: 10px;
    box-shadow: 0px 20px 10px -10px #0004;
    padding: 10px 20px;
    width: calc(100% - 20px);
    text-align: center;
}