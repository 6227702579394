.mx-pagination-main{
    padding: 30px;
}
.mx-page-title{
    font-size: 24px;
}
.mx-pagination{
    font-size: 14px;
}
.mx-pagination .mx-pagination-link:last-child{
    pointer-events: none;
}
.mx-pagination-link{
    color: #80c44d;
}
.mx-pagination-link:hover,
.mx-pagination-link.current{
    color: #0a003f;
    text-decoration: none;
}
.mx-pagination-link + .mx-pagination-link::before{
    content: "/";
    margin: 0px 0.5em;
    color: #80c44d;
}