.spinning{
    animation: loaderSpin 1000ms linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
}
.spinning.slow{
    animation: loaderSpin 5000ms linear infinite;
}
.spinning.bigger{
    font-size: 50px;
}

@keyframes loaderSpin {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}