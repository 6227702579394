.mx-sidebar-overlay{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 90;
}
.mx-sidebar-overlay:hover{
    cursor: pointer;
}
.mx-sidebar-outer{
    display: flex;
    background-color: var(--bg-color-pr);
}
.mx-sidebar-fixed{
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.mx-sidebar-fixed ul{
    margin: 0px;
}
.mx-sidebar-fixed__brand{
    width: 20px;
    height: 20px;
    object-fit: contain;
}
.mx-sidebar-fixed__icons{
    padding: 0px;
    color: var(--white);
}
.mx-sidebar-fixed__icon{
    cursor: pointer;
    display: grid;
    place-items: center;
    font-size: 18px;
    padding: 10px;
    color: var(--text-gray);
}
.mx-sidebar-fixed__icon i{
    position: relative;
}
.mx-sidebar-fixed__icon:hover{
    color: var(--text-gray);
    text-decoration: none;
}
.mobile-icon{
    display: none;
}








.mx-sidebar-slideable{
    padding: 0px 20px;
    border-radius: 5px;
    width: 290px;
    height: calc(100vh - 20px);
    margin: 10px;
    background-color: var(--bg-color);
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    transition: transform 225ms 0ms, visibility 0ms 225ms;
    transform: translateX(calc(-100% - 20px));
    visibility: hidden;
}
.mx-sidebar-slideable.sidebar-active{
    transform: translateX(20%);
    transition: transform 225ms 0ms, visibility 0ms 0ms;
    visibility: visible;
}
.mx-sidebar-card .mx-sidebar-card-header{
    color: var(--text-color);
    padding: 20px 0;
    position: relative;
    box-shadow: none;
}
.mx-sidebar-card .mx-sidebar-card-header h2 {
    font-size: 16px;
    color: #212121;
    position: relative;
}
.mx-sidebar-card .mx-sidebar-card-body{
    font-size: 14px;
    padding: 20px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border-radius: 0.55rem;
    background: var(--white);
    color: var(--text-gray);
    font-weight: 400;
    transition: all 0.2s ease-in-out;
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.menu a{
    display: block;
    border-radius: 0.1875rem;
    line-height: 24px;
    text-transform: uppercase;
    text-align: left;
    transition: all .3s;
    padding: 15px;
    text-decoration: none;
    color: var(--bg-color-sc);
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.menu a:hover{
    background-color: var(--bg-color-pr);
    color: var(--white);
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.menu a i{
    margin-right: 15px;
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.messages .media{
    display: flex;
    align-items: flex-start;
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.messages a{
    text-decoration: none;
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.messages .media .media-object{
    width: 36px;
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.messages li{
    margin-bottom: 20px;
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.messages .media .media-body{
    margin-left: 10px;
    color: var(--text-color);
    font-size: 14px;
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.messages .media .media-body .name .time{
    float: right;
    color: var(--text-gray);
}
.mx-sidebar-card .mx-sidebar-card-body .list-unstyled.messages .media .media-body .message{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 165px;
}











.mx-sidebar-main{
    width: 230px;
    padding: 10px;
    flex-shrink: 0;
    height: 100vh;
    overflow-y: auto;
    background-color: var(--bg-color);
}
.mx-sidebar-main__profile-wrap{
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid #eee;
}
.mx-sidebar-main__profile-image{
    height: 80px;
    width: 80px;
    border: 3px solid #ffffff;
    border-radius: 50%;
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 50%);
}
.mx-sidebar-main__profile-image.smaller{
    height: 40px;
    width: 40px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.mx-sidebar-main__profile-name{
    margin: 0.6em 0px;
}
.mx-sidebar-main__profile-email{
    font-size: 15px;
    margin: 0.6em 0px;
}
.mx-sidebar-logo{
    margin-bottom: 110px;
    position: relative;
}
.mx-sidebar-logo a,
.mx-sidebar-logo img{
    display: block;
}
.mx-sidebar-logo img{
    height: 79px;
    width: auto;
    object-fit: contain;
    padding: 0px 15px;
    margin: auto;
}
/* .mx-sidebar-collapse,
.mx-menu-collapse{
    position: absolute;
    z-index: 1;
    right: 0%;
    top: 125%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    border: none;
    border-right: 0px;
    border-radius: 5px 0px 0px 5px;
    background-color: #ffffff;
    color: #000000;
}
.mx-sidebar-collapse:hover,
.mx-menu-collapse:hover{
    cursor: pointer;
    box-shadow: 0px 0px 5px #0005;
}
.mx-sidebar-collapse:focus,
.mx-menu-collapse:focus{
    outline: none;
}
.mx-sidebar-collapse.collapsed{
    width: calc(100% - 10px);
    left: 5px;
    border-radius: 5px;
}
.mx-menu-collapse{
    display: none;
} */


.mx-sidebar-navigation-wrap{
    font-size: 15px;
    padding: 15px 0px;
}
.mx-sidebar-navigation.active .mx-sidebar-navigation-icon{
    color: var(--text-gray);

}
.mx-sidebar-navigation-icon{
    font-size: 16px;
}
.mx-sidebar-navigation-icon.icon-big{
    font-size: 20px;
}
.mx-sidebar-navigation{
    padding: 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--text-color);
}
.mx-sidebar-navigation:hover,
.mx-sidebar-navigation:focus{
    color: var(--text-color);
    text-decoration: none;
}
.mx-sidebar-navigation.active{
    color: var(--white);
    background-color: var(--bg-color-pr);
}
.mx-sidebar-hamburger{
    font-size: 30px;
    position: fixed;
    z-index: 9;
    top: 12px;
    left: 30px;
    display: none;
}

.notify{
    position: absolute;
    bottom: 100%;
    left: 100%;
}
.heartbit{
    background-color: var(--text-gray);
    position: absolute;
    height: 25px;
    width: 25px;
    top: -10px;
    right: -16px;
    z-index: 10;
    border-radius: 50%;
    animation: heartbit 1s ease-out infinite;
}
.notify .point{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--white);
    position: absolute;
    z-index: 11;
    left: 100%;
    top: 0px;
}
@keyframes heartbit{
    0% {
        transform: scale(0);
        opacity: 0
    }

    25% {
        transform: scale(0.1);
        opacity: .1
    }

    50% {
        transform: scale(0.5);
        opacity: .3
    }

    75% {
        transform: scale(0.8);
        opacity: .5
    }

    100% {
        transform: scale(1);
        opacity: 0
    }
}
@media(max-width:1200px){
    .mx-sidebar-main{
        position: fixed;
        z-index: 99;
        top: 0%;
        left: 0%;
        transition: transform 225ms 0ms, visibility 0ms 225ms;
        transform: translateX(-100%);
        visibility: hidden;
    }
    .mx-sidebar-main.sidebar-active{
        border-radius: 5px;
        transform: translateX(25%);
        transition: transform 225ms 0ms, visibility 0ms 0ms;
        visibility: visible;
        box-shadow: 0px 0px 20px #0005;
    }
    .mobile-icon{
        display: block;
    }
    .mx-menu-collapse{
        display: block;
    }
    .mx-sidebar-hamburger{
        display: block;
    }
}
@media(max-width:768px){
    .mx-sidebar-main.sidebar-active{
        width: calc(100% - 80px);
        height: calc(100vh - 30px);
        top: 15px;
        left: revert;
        right: 15px;
        transform: translateX(0px);
    }
    .mx-sidebar-slideable.sidebar-active{
        width: calc(100% - 80px);
        transform: translateX(0%);
        left: revert;
        right: 5px;
    }
}