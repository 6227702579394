.counter-wrap{
    display: flex;
    align-items: center;
    margin: 15px 0px;
    gap: 10px;
}
 .counter-color{
    height: 10px;
    width: 10px;
    min-height: 10px;
    min-width: 10px;
    border-radius: 50%;
    background-color: #b5b5b5;
}
 .counter-color.primary{
    background-color: #010138;
}
 .counter-color.secondary{
    background-color: #80c44d;
}

.counter-wrap .counter-title{
    font-size: 20px;
    font-weight: 400;
    margin: 0px;
    color: #010138;
    text-align: left;
    display: flex;
    gap: 0.4em;
    white-space: nowrap;
}


@media screen and (max-width:768px){
    .counter-main{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }
    .counter-wrap .counter-color{
        height: 12px;
        width: 12px;
        min-height: 12px;
        min-width: 12px;
    }
    .counter-wrap{
        margin: 0px;
        gap: 5px;
    }
    .counter-wrap .counter-title{
        font-size: 15px;
        
    }
}