.opentok-main-wrap{
    position: fixed;
    display: flex;
    gap: 10px;
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    z-index: 99;
    background-color: #353535;
    padding: 10px;
    padding-bottom: 0px;
}
.call-alert{
    position: absolute;
    z-index: 9999999;
    padding: 20px 50px;
    border: 1px solid #fff;
    background-color: #000c;
    color: #fff;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 5px 5px 10px #0005;
    pointer-events: none;
}
.opentok-main-wrap .opentok-left{
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    gap: 15px;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    flex-grow: 1;
    padding-bottom: 80px;
}
.opentok-main-wrap .opentok-left::-webkit-scrollbar{
    width: 10px;
    background-color: #222222;
}
.opentok-main-wrap .opentok-left::-webkit-scrollbar-thumb{
    background-color: #656565;
    margin: 1px;
}
.publisher-streams-holder{
    display: flex;
    align-items: flex-end;
    position: relative;
    margin-top: auto;
}
.publisher-streams-holder .OTPublisherContainer{
    position: absolute;
    bottom: 10px;
    right: 10px;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 20px #0005;
    height: 150px!important;
    width: 200px!important;
}

.subscriber-streams-holder{
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(7, 1fr);
}
.subscriber-streams-holder .waiting-text{
    color: #fff;
    white-space: nowrap;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-80%);
    font-weight: 300;
}
.subscriber-streams-holder .OTSubscriberContainer{
    height: 170px!important;
    width: 100%!important;
}


.opentok-main-wrap .controls-holder{
    position: fixed;
    left: 0;
    bottom: 0;
    width: calc(100% - 408px);
    min-height: 50px;
    background-color: #424242;
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 10px;
    box-shadow: 0px 5px 20px #0005;
}
.opentok-control-button{
    height: 50px;
    width: 50px;
    font-size: 20px;
    background-color: #d5d5d5;
    box-shadow: 5px 5px 10px #0002;
    color: #000;
    border-radius: 50%;
    border: 1px solid #f0f0f0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 225ms;
}
.opentok-control-button img{
    max-width: calc(100% - 25px);
}
.opentok-control-button.button-off{
    opacity: 0.3;
}
.opentok-control-button:hover{
    transform: scale(1.1);
}
.opentok-control-button.end-call{
    background-color: #e71a1a;
    color: #ffffff;
}
.opentok-control-button.toggle-chat{
    display: none;
}
.screen-share-wrap{
    flex-grow: 1;
    height: 70vh;
    position: relative;
}
.screen-share-wrap .banner-image{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.screen-share-wrap > div{
    height: 100%;
}
.subscriber-streams-holder .custom-subscriber-wrap{
    position: relative;
    overflow: hidden;
}
.subscriber-streams-holder .custom-subscriber-wrap .custom-subscriber-name{
    position: absolute;
    left: 0px;
    text-align: center;
    bottom: 0px;
    color: #f0f0f0;
    width: calc(100% - 0.4em);
    padding: 0.4em 0.2em;
    background-color: #0002;
    z-index: 999;
    transform: translateY(100%);
    transition: transform 300ms;
}
.subscriber-streams-holder .custom-subscriber-wrap:hover .custom-subscriber-name{
    transform: translateY(0%);
}
.screen-share-wrap .OTSubscriberContainer,
.screen-share-wrap .OTPublisherContainer{
    position: absolute;
    z-index: 99;
    top: 0px;
    left: 0px;
    width: 100%!important;
    height: 100%!important;
}

/* .screen-share-wrap .OTSubscriberContainer .OT_video-element{
    object-fit: cover;
} */


/* small laptops Responsive */
@media(max-width:1500px){
    .subscriber-streams-holder{
        grid-template-columns: repeat(4, 1fr);
    }
    .screen-share-wrap{
        height: 64vh;
    }
    .opentok-main-wrap .controls-holder{
        width: calc(100% - 308px);
    }
    .publisher-streams-holder .OTPublisherContainer{
        height: 100px!important;
        width: 150px!important;
    }
    .screen-share-wrap .OTSubscriberContainer, .screen-share-wrap .OTPublisherContainer{
        height: 100%!important;
        width: 100%!important;
    }
}

/* Tablet Responsive */

@media(max-width:992px){
    .opentok-main-wrap .controls-holder{
        width: 100vw;
        padding: 15px 0px;
        z-index: 9999;
    }
    .opentok-control-button.toggle-chat{
        display: flex;
    }
    .publisher-streams-holder{
        margin-bottom: 35px;
    }
    
}