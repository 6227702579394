.call-support:hover{
    cursor: pointer;
}
.custom-swal-icon{
    border: none!important;
}
.custom-swal-icon img{
    height: 80px;
    width: 80px;
    object-fit: contain;
}