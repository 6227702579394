.chat-holder{
    width: 400px;
    background-color: #222222;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    height: 100%;
    max-height: 100%;
    padding-bottom: 50px;
}
.chat-holder .chat-messages-wrap{
    height: 100%;
    max-height: calc(100% - 20px);
    overflow-y: scroll;
}
.chat-holder .chat-messages-wrap::-webkit-scrollbar{
    width: 0px;
}
.chat-holder .chat-message-typing-wrap{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    border: 1px solid #666666;
    border-left: none;
    border-right: none;
}
.chat-holder .chat-input-wrap{
    padding: 15px;
    background-color: #353535;
    display: flex;
}
.chat-holder .chat-input{
    flex-grow: 1;
    background-color: #282828;
    border: 1px solid #656565;
    padding: 0.5em 1em;
    color: #ffffff;
    min-width: 30px;
}
.chat-holder .chat-send-button{
    padding: 0.2em 2em;
    margin-left: 0.5em;
    background-color: #8bc240;
    border: none;
    color: #000;
}
.chat-holder .chat-attach-file-button{
    font-size: 20px;
    background-color: #444444;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    color: #ffffff;
    margin-right: 5px;
    border: 1px solid #7e7e7e;
    border-radius: 5px;
}
.chat-holder .chat-attach-file-button:hover{
    background-color: #353535;
    cursor: pointer;
}
.chat-holder .chat-send-button:hover{
    cursor: pointer;
    transform: scale(0.95);
    opacity: 0.7;
}

.chat-holder .chat-message-wrap{
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.chat-holder .chat-message-wrap.is-own-message{
    align-items: flex-end;
}
.chat-holder .chat-message-wrap.is-own-message .chat-message-text{
    background-color: #8bc240;
}
.chat-holder .chat-message-text{
    margin: 0px;
    color: #000;
    max-width: 70%;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
    word-break: break-word;
}
.chat-holder .chat-message-sender-name{
    color: #999;
    font-size: 75%;
}

@media(max-width:1500px){
    .chat-holder{
        width: 300px;
    }
}
@media(max-width:992px){
    .chat-holder{
        position: fixed;
        padding: 0px;
        width: 100vw;
        left: 0px;
        top: 0px;
        z-index: 999;
        transform: translateX(100%);
        transition: transform 225ms;
    }
    .chat-holder.is-active{
        transform: translateX(0%);
    }
    .chat-holder .chat-message-typing-wrap{
        bottom: 80px;
    }
    .chat-holder .chat-messages-wrap{
        max-height: calc(100% - 150px);
    }
    .chat-holder .chat-message-text{
        font-size: 20px;
    } 
}