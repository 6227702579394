.iconStyle {
    font-size: 2rem;
    padding: 22px;
    color: #fff;
    background: #1fb9d8;
    -webkit-box-shadow: 0 14px 26px -12px rgba(140,140,249,0.42),0 4px 23px 0px rgba(0,0,0,0.12),0 8px 10px -5px rgba(140,140,249,0.2) !important;
    box-shadow: 0 14px 26px -12px rgba(17, 17, 17, 0.42),0 4px 23px 0px rgba(187, 32, 32, 0.12),0 8px 10px -5px rgba(46, 46, 51, 0.2) !important;
    border-radius: 100px;
    margin-top: -40px;
    
}