@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
.quotation-card-wrap{
    background-color: #ffffff;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 20px #0003;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    padding-bottom: 20px;
    --color-sec: var(--bg-color-pr);
    --color-pri: var(--bg-color-sc);
    --color-third:rgb(232, 185, 95);
}

























.audiowide{
    font-family: audiowide;
}
.color-white{
    color: var(--white);
}
.color-black{
    color: black;
}
.color-1{
    color: var(--color-pri);
}
.color-2{
    color: var(--color-sec);
}
.color-3{
    color: var(--color-third);
}
.bg-1{
    background-color: var(--color-pri);
}
.bg-2{
    background-color: var(--color-sec);
}
.bg-3{
    background-color: var(--color-third);
}
.logo-image{
    width: 60%;
}
.text-center{
    text-align: center;
}
.address{
    margin: 0px;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
}
.quotation-card-wrap .quotation-row-icons{
    display: flex;
    font-size: 16px;
    gap: 10px;
    justify-content: space-between;
    margin-top: 15px;
}
.quotation-card-wrap .quotation-row-icons .quotation-row-icon{
    border-radius: 5px;
    display: block;
    padding: 5px 10px;
    background-color: var(--color-pri);
    color: var(--white);
    box-shadow: 2px 8px 10px -5px #00000038;
    white-space: nowrap;
}
.quotation-card-wrap .quotation-row-icons .quotation-row-icon:hover{
    cursor: pointer;
    color: var(--white);
}
.top-section-wrap{
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid var(--color-sec);
}
.top-section-wrap > *{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.top-section-right{
    background-color: var(--color-pri);
}
.bottom-section-wrap{
    padding: 20px;
    font-size: 16px;
}
.details-wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.details-wrap >*{
    width: 50%;
}
.details-left{
    display: flex;
    justify-content: flex-start;
}
.details-right{
    display: flex;
    justify-content: flex-end;
}
.details-right-heading{
    font-size: 40px;
    letter-spacing: 2px;
    margin: 0.5em 0px;
    margin-top: 0px;
    padding: 0.1em 0px;
    border-bottom: 2px solid var(--color-third);
}
.inner-wrap .details-text{
    margin: 5px 0em;
    padding-right: 50px;
}
.heading-text{
    font-size: 17px;
    font-style: italic;
    color: #666666;
}
.custom-list{
    list-style: none;
    padding: 0px;
}
.custom-list li{
    padding-left: 2em;
    margin: 15px 0px;
    position: relative;
}
.custom-list li::before{
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    background-color: var(--color-third);
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
}
.custom-table-wrap{
    margin: 30px 0px;
    font-size: 14px;
}
.custom-table-row{
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 1fr 1fr 1fr;
    background-color: rgb(240, 240, 240);
}
.custom-table-row:nth-child(even){
    background-color: rgb(224, 228, 229);
}
.custom-table-data + .custom-table-data:not(.custom-table-head){
    border-left: 1px solid var(--color-third);
}
.custom-table-data{
    padding: 5px 10px;
    width: 100%;
    border-left: 1px solid transparent;
}
.custom-table-head{
    font-weight: 700;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.total-wrap{
    font-size: 14px;
}
.total-top{
    border-left: 2px solid var(--color-third);
}
.total-bottom{
    margin: 10px 0px;
}
.total-row{
    border-bottom: 2px solid var(--color-third);
    margin: 0px 10px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
}
.total-row + .total-row{
    padding-top: 5px;
}
.total-row:last-child{
    padding-bottom: 0px;
    border-bottom: 0px;
}
.total-bottom .total-row{
    padding: 5px 0px;
}
.terms-title{
    font-size: 12px;
    margin-top: 1.5em;
    border-bottom: 2px solid rgb(213,65,89);
    color: rgb(213, 65, 89);
    padding: 5px 0px;
    max-width: 300px;
}
.quotation-buttons-wrap .button{
    padding: 0.3em 1.5em;
    margin: 0px 10px;
    border-radius: 5px;
    border: none;
    color: var(--white);
}
.button:hover{
    cursor: pointer;
}



.bold{
    font-weight: 600;
}
.flex{
    display: flex;
}
.flex-row{
    flex-direction: row;
}
.flex-column{
    flex-direction: column;
}



































/* Mobile Optimization */

@media(max-width:768px){
    .details-wrap{
        flex-wrap: wrap;
    }
    .details-wrap >*{
        width: 100%;
    }
    .details-right,
    .details-left{
        width: 100%;
    }
    .details-right-heading{
        margin-top: 0px;
    }
    .heading-text{
        padding-bottom: 8px;
    }
    .custom-table-wrap{
        overflow: scroll;
    }
    .address{
        font-size: 16px;
    }
    .logo-image{
        width: 100%;
    }
    .quotation-card-wrap .quotation-row-icons .quotation-row-icon{
        font-size: 13px;
    }
    .quotation-card-wrap .quotation-row-icons{
        justify-content: flex-end;
    }
    .custom-table-data{
        word-break: break-word;
    }
    .inner-wrap{
        width: 100%;
    }
    .mobile-order-1{
        order: 1;
    }
}