.animated-text-loader{
    font-size: 30px;
}
.animated-text-loader span:nth-child(1){
    animation: textloader1 1s linear infinite;
}
.animated-text-loader span:nth-child(2){
    animation: textloader2 1s linear infinite;
}
.animated-text-loader span:nth-child(3){
    animation: textloader3 1s linear infinite;
}


@keyframes textloader1{
    0%{
        opacity: 0;
    }
    25%{
        opacity: 1;
    }
    100%{
        opacity: 1;
    }
}
@keyframes textloader2{
    0%{
        opacity: 1;
    }
    25%{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes textloader3{
    0%{
        opacity: 1;
    }
    25%{
        opacity: 1;
    }
    50%{
        opacity: 1;
    }
    75%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}