.mx-input-wrap{
    --font-size:16px;
    --speed:225ms;
    --border-color:#e3e3e3;
    --text-color:#333;
    --bg: #f0f0f0;
    --color-pri: var(--bg-color-sc);
    --color-pri-light:var(--bg-color-sc-light);
    --color-sec: var(--bg-color-pr);
    --color-sec-light: var(--bg-color-pr-light);
    --color-third:rgb(232, 185, 95);
    position: relative;
    margin: calc(var(--font-size)) 0px;
    width: 100%;
    padding: 0px;
    background-color: transparent;
    border: none;
}
.mx-input-wrap.input-thin{
    --font-size:14px;
}
.mx-input-wrap + .mx-input-wrap{
    margin-top: 0px;
}
.mx-input-wrap.primary{
    position: relative;
    --speed:225ms;
    --border-color:#e3e3e3;
    --text-color: var(--color-pri);
    --bg:var(--color-pri-light);
}
.mx-input-wrap.secondary{
    position: relative;
    --speed:225ms;
    --border-color:#b5b5b5;
    --text-color:var(--color-sec);
    --bg:var(--color-sec-light);
}
.mx-input-wrap input,
.mx-input-wrap textarea,
.mx-input-wrap select{
    font-size: var(--font-size);
    width: 100%;
    max-width: 100%;
    border: 1px solid var(--border-color);
    padding: 0.6em 1em;
    border-radius: 1.5em;
}
.mx-input-wrap input:focus,
.mx-input-wrap textarea:focus,
.mx-input-wrap select:focus{
    outline: none;
    border-color: var(--color-sec-light);
}
.mx-input-wrap select:focus{
    background-color: #ffffff;
}
.mx-input-wrap.animated-label input::placeholder,
.mx-input-wrap.animated-label textarea::placeholder{
    display: none;
    opacity: 0;
    visibility: hidden;
}
.mx-input-wrap.animated-label :is(input:focus, input:not(:placeholder-shown)) ~ .label,
.mx-input-wrap.animated-label :is(textarea:focus, textarea:not(:placeholder-shown)) ~ .label{
    top: -0.7em;
    left: 0.5em;
    transform: scale(0.85);
    color: var(--color-sec-light);
    background-color: #fff;
    opacity: 1;
}
.mx-input-wrap .label{
    position: static;
}
.mx-input-wrap.animated-label .label{
    opacity: 0.5;
    transition: var(--speed);
    font-size: var(--font-size);
    font-weight: 600;
    padding: 0px 5px;
    color: var(--color-sec-light);
    position: absolute;
    top: 0.6em;
    pointer-events: none;
    left:1em;
}
.input-right-icon{
    cursor: pointer;
    font-size: 1.4em;
    position: absolute;
    right: 0.7em;
    top: calc(var(--font-size) - 2px);
}
.mx-input-error{
    color: rgb(239, 45, 45);
    font-size: 13px;
    margin-top: 0.2em;
    font-weight: 500;
}
.mx-input-info{
    color: #454545;
    font-size: 13px;
    font-style: italic;
    margin-top: 0.2em!important;
    font-weight: 500;
}

.mx-input-wrap button{
    border: none;
    font-weight: 600;
    border-radius: 1.5em;
    background-color: #f0f0f0;
    color: #111111;
    padding: 0.5em 1.5em;
    transition: 150ms;
}
.mx-input-wrap.mx-large button{
    font-size: 18px;
}
.mx-input-wrap button:hover,
.mx-input-wrap button:focus{
    box-shadow: 5px 5px 10px #0003;
    transform: translateY(-2px);
    cursor: pointer;
}
.mx-input-wrap button:focus{
    outline: none;
}
.mx-input-wrap.mx-centered button{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.mx-input-wrap.mx-left button{
    display: block;
    margin-right: auto;
}
.mx-input-wrap.mx-right button{
    display: block;
    margin-left: auto;
}
.mx-input-wrap.full-width button{
    width: 100%;
}
.mx-input-wrap.primary button{
    background-color: var(--white);
    border:1px solid var(--color-sec);
    color: var(--color-sec);
}

.mx-input-wrap.secondary button{
    background-color: var(--color-sec);
    color: #ffffff;
}
.mx-input-wrap.secondary button:hover{
    background-color: var(--color-sec-light);
}

.form-group .form-control+.input-group-addon, .input-group .form-control+.input-group-addon {
    padding: 10px 18px 10px 0;
    position: absolute;
    right: 0px;
    bottom: 0em;
}