/* .column {
    display: flex !important;
} */



.equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.equal-height .card-content {
  flex: auto;
}

.ul-Padding{
  padding-left: 0px !important; 
}
.package-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  white-space: nowrap;
}

.message{
  background-color: #ffffff;
}
.message .message-title{
  color: var(--text);
  font-size: 18px;
  margin: 0px;
}
.message .message-header{
  background-color: var(--white);
  border: 1px solid var(--bg-color-pr);
  cursor: pointer;
  margin-bottom: 15px;
  margin-top: 15px;
  box-shadow: 10px 10px 10px #00000010;
}
.message .message-header .btn{
  background-color: var(--bg-color-pr);
  color: var(--white);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0px;
}
.message .columns{
  padding: 10px;
}
.mx-package-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75em 1em;
}
.mx-packages{
  background-color: transparent;
}
  