:root{
  --bg-color:#f4f7f6;
  --bg-color-pr:#313740;
  --bg-color-pr-light:#414751;
  --bg-color-sc:#55ba18;
  --bg-color-sc-light:#73db33;
  --white:#ffffff;
  --text-color:#212121;
  --text-gray:#afafaf;
  --yellow:rgb(244 183 35);
  --green:rgb(85 186 24);
  --blue:rgb(89 203 255);
}
.clr-pr{
  color: var(--bg-color-pr);
}
.clr-sc{
  color: var(--bg-color-sc);
}
.hover\:clr-pr:hover{
  color: var(--bg-color-pr);
}
.hover\:clr-sc:hover{
  color: var(--bg-color-sc);
}


.bg-pr{
  background-color: var(--bg-color-pr)!important;
}
.bg-sc{
  background-color: var(--bg-color-sc)!important;
}
.hover\:bg-pr:hover{
  background-color: var(--bg-color-pr)!important;
}
.hover\:bg-sc:hover{
  background-color: var(--bg-color-sc)!important;
}


.clr-yellow{
  color: var(--yellow);
}
.clr-green{
  color: var(--green);
}
.clr-blue{
  color: var(--blue);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.navbar{
  z-index: 1;
}
.navbar.sticky{
  position: fixed!important;
  z-index: 99;
  width: 100vw;
  top: 0;
  left: 0;
}
.navbar.sticky,
.navbar.sticky .navbar{
  padding-top: 0px;
  padding-bottom: 0px;
  
}
.navbar-wrapper{
  height: auto;
}

.navbar-menu.is-active{
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.navbar-menu.is-active .navbar-item h1,
.navbar-menu.is-active .navbar-item big{
  padding: 10px 50px;
  font-size: 18px;
}
.navbar-menu.is-active .navbar-item .dropdown-menu{
  left: 50%;
  transform: translateX(-50%);
  width: 100%!important;
}
.navbar-burger.is-active{
  position: relative;
  z-index: 999;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.quotation-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
  .quotation-bg {    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 330px;

  } 

    .quotation-bg-heading {
      display: flex;
      align-items: center;
      font-size: 3em;
      /* color: #fff; */
      height: inherit;
      text-transform: capitalize;
      
    }

  .quotaion-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 5em 0;
  }
   

    .quotaion-primary-text {
      /* color: map-get($theme-colors, heading-color); */
      font-size: 1.75em;
     

      
    }

    .quotaion-secondary-text {
      /* color: map-get($theme-colors, heading-color); */
      font-size: 1.15em;
      line-height: 2em;

      
    }


  .quotaion-price-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 2em 0;
  }
    .price-heading {
      /* color: map-get($theme-colors, heading-color); */
      font-size: 1.25em;
      
      text-transform: capitalize;
      padding-right: 1em;

      
    }

    .quotaion-price-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ;
      padding: 0.75em 2em;
      cursor: pointer;

    }

      .pdf-icon {
        width: 2em;
        height: 2em;
        /* fill: map-get($theme-colors, error-color); */
        margin-right: 0.5em;

        
      }
      .tab-text {
        /* color: map-get($theme-colors, heading-color); */
        font-size: 1em;
        
        text-transform: capitalize;

        
      }
    
  

  .qoute-link-text {
    /* color: map-get($theme-colors, primary-text-color); */
    border-bottom: 1px solid ;
    margin-left: 0.25em;
  }
    
  

  .quotaion-btn {
    font-size: 1.15em;
    font-weight: normal;
    margin-bottom: 2.5em;
    margin-bottom: 4em;
    padding: 0.75em 2em;

    
  }
  .quotation-contact-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
    .quotaion-primary-text {
      font-size: 1.25em;
      padding-right: 0.2em;
      

     
    }
  


.quotation-table-left-data,
.quotation-table-right-data {
  padding: 1.5em;
  text-align: left;
}


  


.quotation-table-left-data {
  width: 25%;
  /* color: map-get($theme-colors, heading-color); */
  font-size: 20px;
  font-weight: bold;

  
}

.quotation-table-right-data {
  /* color: map-get($theme-colors, heading-color); */
  font-size: 20px;
  width: 25%;

  
}

.quotation-table-container {
  border-collapse: collapse;
  width: 100%;
  background-color: #f5f5f5;
}

.quotation-table-row 
  :nth-child(2n) {
    /* border-top: 1px solid ; */
    border-bottom: 1px solid ;
  
}
.firstPage{
	height:1200px;
	background-position: top;
	background-repeat: no-repeat;
	
	}
	.container{
		page-break-after: always;
	}
	.container-fluid{
		page-break-after: always;
	}
  .heading{
    font-weight:bold;
     font-family:Arial;
      font-size:21.8px; color:#23648c
  }
  .Contents{
    font-family:Arial;
     font-size:18.4px;
      color:#000000
  }
  .hovergreencolor:hover{
    color:#8bc240 !important;
    font-weight:bold !important;

  }
  .roundButton{
    border-radius: 18px !important;
  }

  .button-disabled{
    pointer-events: none;
    cursor: not-allowed;
    opacity: .5;
  }
  .button-disabled[disabled]{
    background-color: var(--bg-color-pr);
  }
  .custom-button{
    background-color: var(--bg-color-pr);
    color: #ffffff;
    padding: 0.5em 2em;
    border: 0px;
    border-radius: 5px;
  }
  .custom-button[disabled]{
    opacity: 0.5;
    pointer-events: none;
  }
  .custom-button:hover{
    cursor: pointer;
    background-color: var(--bg-color-pr-light);
  }
  .custom-button:active{
    transform: scale(0.93);
  }
  .custom-button:focus{
    outline: none;
  }
  .hidden-input{
    display: none;
  }
.buttons-po{
  display: flex;
  justify-content: right;
  gap: 20px;
  padding: 5px 10px;
}
.button-po{
  padding: 0px;
  border: none;
  background-color: transparent;
  width: 35px;
  height: 35px;
}
.button-po img{
  width: 100%;
  max-width: 100%;
}

/* mx modal START */
.mx-modal-bg{
  position: fixed;
  z-index: 99;
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #00000050;
  display: grid;
  place-items: center;
}
.mx-modal-inner{
  position: relative;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: calc(100% - 25px);
  max-width: 1000px;
}
.mx-modal-title{
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #e5e5e5;
}
.mx-modal-close-icon{
  position: absolute;
  font-size: 25px;
  top: 15px;
  right:15px;
  cursor: pointer;
}
.mx-modal-items{
  margin-top: 20px;
}
/* mx modal END */



@keyframes animated-backdrop{
  70%{
    transform: scale(1.5);
    opacity: 0;
  }
  100%{
    transform: scale(1.5);
    opacity: 0;
  }
}


.hamburger-icon{
  height: 3rem;
  width: 3rem;
}



.main-content{
  background-color: var(--bg-color);
  height: 100vh;
  max-height: 100vh;
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-color: #ffffff #e5e5e5;
}
.main-content {
  --scrollbarBG: #ffffff;
  --thumbBG: #e5e5e5;
}
.main-content::-webkit-scrollbar {
  width: 15px;
}
.main-content {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.main-content::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.main-content::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.mx-table-button{
  appearance: none;
  font-size: 25px;
  border: 0px;
  background-color: transparent;
  color: var(--text-gray);
  cursor: pointer;
}
.mx-table-button:hover{
  color: var(--bg-color-pr-light);
}
.mx-table-button i{
  font-size: inherit;
}


.mx-scrollable-table-wrap .table-head .table_filter{
  width: 58%!important;
  margin-bottom: 15px;
  margin-top: 15px;
}
.mx-scrollable-table-wrap .table-head .table_filter .form-control{
  padding: 0.5em 1em;
  border-radius: 5px;
  margin-top: 3px;
  font-size: 18px;
}
.mx-scrollable-table-wrap .asrt-table-body{
  margin-top: 0px!important;
}
.mx-table-head{
  position: sticky;
  z-index: 1;
  top: 0px;
  background-color: #ffffff;
}
@media(max-width:1200px){
  .mx-table{
    min-width: 1200px;
  }
  .mx-scrollable-table-wrap{
    overflow: scroll;
  }
}
.mx-subservice-title{
  border: 1px solid #b5b5b5;
  background-color: #ffffff;
  box-shadow: 5px 5px 5px #0002;
  padding: 0.8em 1em;
  font-weight: 600;
  color: #151515;
  font-size: 20px;
}
.mx-subservice-holder{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 20px;
}
@media(max-width:1200px){
  .mx-subservice-holder{
    grid-template-columns: repeat(2, 1fr);
  }
}
@media(max-width:767px){
  .mx-subservice-holder{
    grid-template-columns: repeat(1, 1fr);
    padding: 0px;
  }
}
.mx-services-bg{
  background-color: #ffffff;
  border: 1px solid #b5b5b5;
  padding: 20px;
  margin: 50px 0px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px #0003;
}
.mx-checkbox:focus{
  outline: none;
  background-color: #aee68450;
}
.mx-checkbox{
  text-align: left;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  margin: 15px 10px;
  border: 1px solid #d5d5d5;
  border-top: 2px solid #80c44d;
  box-shadow: 0px 15px 10px -10px #0003;
  background-color: #ffffff;
  position: relative;
  isolation: isolate;
}
.mx-checkbox:hover{
  cursor: pointer;
}
.mx-checkbox:hover,
.mx-checkbox.checked{
  border-color: #80c44d;
}
.mx-checkbox::before{
  content: "";
  position: absolute;
  pointer-events: none;
  background-color: #aee684;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0px;
  transform-origin: left;
  transform: scalex(0);
  z-index: -1;
  transition: transform 225ms;
}
.mx-checkbox.checked::before{
  transform: scalex(1);
}
.mx-checkbox .mx-checkbox-icon{
  height: 20px;
  width: 20px;
  display: grid;
  place-items: center;
  font-size: 12px;
  font-weight: 800;
  position: absolute;
  left: 100%;
  bottom: 100%;
  transform: translate(-50%, 50%);
  background-color: #fff;
  border: 2px solid #80c44d;
  border-radius: 50%;
}
.mx-checkbox input[type="checkbox"]{
  display: none;
}














/* Temp fix */
.OT_ModalDialog{
  display: none!important;
}

.card .body {
  font-size: 14px;
  padding: 20px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
  background: #fff;
  color: #616161;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
}
.card .body:hover{
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
}

.l-green {
  background: linear-gradient(45deg, #9ce89d, #cdfa7e) !important
}

.l-pink {
  background: linear-gradient(45deg, pink, #fbc1cb) !important
}

.l-turquoise,.total_earnings .chart div {
  background: linear-gradient(45deg, #00ced1, #08e5e8) !important;
  color: #fff !important
}

.l-cyan {
  background: linear-gradient(45deg, #49cdd0, #00bcd4) !important;
  color: #fff !important
}

.l-khaki {
  background: linear-gradient(45deg, khaki, #fdf181) !important
}

.l-coral {
  background: linear-gradient(45deg, #f08080, #f58787) !important;
  color: #fff !important
}

.l-salmon {
  background: linear-gradient(45deg, #ec74a1, #fbc7c0) !important;
  color: #fff !important
}

.l-blue {
  background: linear-gradient(45deg, #72c2ff, #86f0ff) !important;
  color: #fff !important
}

.l-seagreen {
  background: linear-gradient(45deg, #8ed8ec, #85f7b5) !important;
  color: #fff !important
}

.l-amber,.w_calender span+span,.w_calender em {
  background: linear-gradient(45deg, #fda582, #f7cf68) !important;
  color: #fff !important
}

.l-blush {
  background: linear-gradient(45deg, #dd5e89, #f7bb97) !important;
  color: #fff !important
}

.l-parpl,.total_earnings .chart.expense div {
  background: linear-gradient(45deg, #a890d3, #edbae7) !important;
  color: #fff !important
}

.l-slategray {
  background: linear-gradient(45deg, #708090, #7c8ea0) !important;
  color: #fff !important
}

.l-dark {
  background: linear-gradient(45deg, #555, #333) !important;
  color: #fff !important
}

.xl-pink {
  background: #f9edee !important
}

.xl-turquoise {
  background: #bdf3f5 !important
}

.xl-khaki {
  background: #f9f1d8 !important
}

.xl-parpl {
  background: #efebf4 !important
}

.xl-salmon {
  background: #ffd4c3 !important
}

.xl-blue {
  background: #e0eff5 !important
}

.xl-seagreen {
  background: #ebf2e8 !important
}

.xl-slategray {
  background: #eceeef !important
}

.bg-black {
  background: #22252b
}

.btn-white {
  background-color: #fff;
  color: #000
}



.card-plain {
  border-radius: .55rem;
  padding-bottom: .7rem;
  max-width: 330px;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 10px 25px 0px rgba(0,0,0,0.3)
}

.card-plain.card-plain {
  margin: 0 auto
}

.card-plain .header img {
  width: 80px;
  border: 3px solid #fff;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.5)
}

.card-plain .footer {
  padding: 15px 0
}
.theme-black .authentication{
  background: var(--bg-color-pr) !important;
}
.container>.content-center{
  min-height: 100vh;
  /* min-width: 100vw; */
  display: grid;
  place-items: center;
}
.authentication .company_detail {
  color: #fff;
}
.m-b-40, .authentication .company_detail .logo{
  margin-bottom: 40px;
}
.authentication .company_detail h3{
  font-weight: 300;
  font-size: 2rem;
  text-transform: uppercase;
  opacity: 0.7;
  letter-spacing: 2px;
}
.authentication .company_detail p {
  opacity: 0.6;
  letter-spacing: 1px;
  font-weight: 300;
}
.footer {
  padding: 24px 0;
}
.footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
.footer ul li{
  display: inline-block;
}
.footer ul li:first-child a{
  padding-left: 0px;
}
.footer ul li a {
  color: inherit;
  padding: 0.5rem;
  font-size: .93em;
  text-transform: uppercase;
  text-decoration: none;
}
.footer ul li a .zmdi{
  display: inline-block;
  font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
  font-size: 18px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;

}
.authentication .company_detail{
  text-align: left;
}
.authentication .company_detail .logo {
  margin-bottom: 40px;
}
.authentication .company_detail .logo img {
  width: 280px;
  vertical-align: top;
}
.authentication .company_detail .social_link li{
  display: inline-block;
}

@media screen and (max-width: 992px){
  .authentication .company_detail {
      text-align: center;
      margin-bottom: 30px;
  }
  .authentication .company_detail *{
    text-align: inherit;
  }
}


.dot-loader{
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 15px 0px;
}
.dot-loader-dot{
  height: 11px;
  width: 11px;
  background-color: var(--text-gray);
  border-radius: 50%;
}
.dot-loader-dot:nth-child(1){
  animation: dot-loader-1 linear infinite 2s;
}
.dot-loader-dot:nth-child(2){
  animation: dot-loader-2 linear infinite 2s;
}
.dot-loader-dot:nth-child(3){
  animation: dot-loader-3 linear infinite 2s;
}

@keyframes dot-loader-1{
  0%{
    transform: scale(1);
  }
  10%{
    transform: scale(1.5);
  }
  20%{
    transform: scale(1);
  }
}


@keyframes dot-loader-2{
  0%{
    transform: scale(1);
  }
  20%{
    transform: scale(1);
  }
  30%{
    transform: scale(1.5);
  }
  40%{
    transform: scale(1);
  }
}


@keyframes dot-loader-3{
  0%{
    transform: scale(1);
  }
  20%{
    transform: scale(1);
  }
  30%{
    transform: scale(1);
  }
  40%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.5);
  }
  60%{
    transform: scale(1);
  }
}
