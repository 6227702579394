.profile-page .profile-top{
    margin-bottom: 60px;
}
.edit-profile-wrap .header h2{
    font-size: 16px;
    color: #212121;
    position: relative;
}

.edit-profile-wrap .body{
    font-size: 14px;
    padding: 20px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    border-radius: 0.55rem;
    background: #fff;
    color: #616161;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
}
.edit-profile-wrap .body:hover {
    box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
    transition: all 0.2s ease-in-out;
}
.user_pic{
    height: 180px;
    width: 180px;
    object-fit: cover;
}
.img-raised{
    box-shadow: 0px 10px 25px 0px rgb(0 0 0 / 30%);
}
.user_pic {
    position: absolute;
    bottom: -70px;
    z-index: 99;
}
.detail {
    margin-left: 250px;
}
.u_name{
    height: 250px;
    margin-bottom: -45px;
    color: var(--text-gray);
}
@media only screen and (max-width: 767px){
    .user_pic {
        position: relative;
        top: 0;
        bottom: auto;
        margin: 0 auto;
    }
    .detail {
        margin: 0;
        padding-top: 15px;
    }
    .u_name{
        height: auto;
        margin: 0px;
    }
    .profile-page .profile-header {
        text-align: center;
    }
}